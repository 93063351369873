<template>
  <div class="user-list">
    <h2>👤 قائمة المستخدمين</h2>
    <table class="table">
      <thead>
      <tr>
        <th>#</th>
        <th>اسم المستخم</th>
        <th>الاسم الكامل </th>
        <th>البريد الإلكتروني</th>
        <th>رقم الهاتف</th>
        <th>الشركة</th>
        <th>الإجراءات</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(user, index) in users" :key="user.id">
        <td>{{ index + 1 }}</td>
        <td>{{ user.username }}</td>
        <td>{{user.fullName}}</td>
        <td>{{ user.email }}</td>
        <td>{{ user.phoneNumber }}</td>
        <td>{{ user.company?.name || '---' }}</td>
        <td>
          <button class="btn btn-warning btn-sm me-1" @click="openResetModal(user.id)">🔐 إعادة تعيين كلمة المرور</button>
          <button class="btn btn-primary btn-sm me-1" @click="openEditModal(user)">✏️ تعديل</button>
          <button class="btn btn-danger btn-sm" @click="deleteUser(user.id)">🗑 حذف</button>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- 🔐 Reset Password Modal -->
    <div class="modal fade" id="resetPasswordModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">إعادة تعيين كلمة المرور</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body position-relative">
            <label class="form-label">كلمة المرور الجديدة</label>
            <input :type="showPassword ? 'text' : 'password'" class="form-control" v-model="resetData.password" placeholder="ادخل كلمة مرور جديدة" />
            <span class="eye-icon position-absolute" @click="togglePassword">👁️</span>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">إلغاء</button>
            <button type="button" class="btn btn-primary" @click="submitReset">تأكيد</button>
          </div>
        </div>
      </div>
    </div>

    <!-- ✏️ Edit User Modal -->
    <div class="modal fade" id="editUserModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">تعديل المستخدم</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <div class="mb-3">
              <label class="form-label">اسم الحساب</label>
              <input type="text" disabled class="form-control" v-model="editUser.username" />
            </div>
            <div class="mb-3">
              <label class="form-label">اسم المستخم</label>
              <input v-model="editUser.fullName" type="text" class="form-control" required placeholder="أدخل اسم المستخم" />
            </div>
            <div class="mb-3">
              <label class="form-label">البريد الإلكتروني</label>
              <input type="email" class="form-control" v-model="editUser.email" />
            </div>
            <div class="mb-3">
              <label class="form-label">رقم الهاتف</label>
              <phone-input
                  :key="editUser.id"
                  @phoneData="(e)=>editUser.phoneNumber = e.number"
                  :value="Number(editUser.phoneNumber)"
                  :allowed=countries
              />

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">إلغاء</button>
            <button type="button" class="btn btn-success" @click="submitEdit">حفظ</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import apiClient from '@/services/apiClient';
import { Modal } from 'bootstrap';
import {countries} from "@/iso";
import { PhoneInput } from '@lbgm/phone-number-input';
import '@lbgm/phone-number-input/style';

const users = ref([]);
const resetData = ref({ userId: null, password: '' });
const editUser = ref({ id: null, username: '',fullName:'', email: '', phoneNumber: ''});

const fetchUsers = async () => {
  try {
    const response = await apiClient.get('/users/all');
    users.value = response.data;
  } catch (error) {
    console.error('Error fetching users:', error);
  }
};

const openResetModal = (userId) => {
  resetData.value = { userId, password: '' };
  const modal = new Modal(document.getElementById('resetPasswordModal'));
  modal.show();
};

const submitReset = async () => {
  try {
    await apiClient.post('/users/reset', {
      userId: resetData.value.userId,
      password: resetData.value.password
    });
    alert('تمت إعادة تعيين كلمة المرور بنجاح');
    const modal = Modal.getInstance(document.getElementById('resetPasswordModal'));
    modal.hide();
  } catch (error) {
    console.error('Reset error:', error);
    alert('فشل إعادة تعيين كلمة المرور');
  }
};

const showPassword = ref(false);


const togglePassword = ()=>{
showPassword.value = !showPassword.value
}
const openEditModal = (user) => {
  editUser.value = {
    id: user.id,
    username: user.username,
    email: user.email,
    fullName: user.fullName,
    phoneNumber: user.phoneNumber,
    role: user.roles[0]?.name || '',
    password: ''
  };

  console.log(typeof editUser.value.phoneNumber,Number(editUser.value.phoneNumber),'phone')
  const modal = new Modal(document.getElementById('editUserModal'));
  modal.show();
};

const submitEdit = async () => {
  try {
    await apiClient.post('/users/update', editUser.value);
    alert('تم تحديث المستخدم بنجاح');
    const modal = Modal.getInstance(document.getElementById('editUserModal'));
    modal.hide();
    fetchUsers();
  } catch (error) {
    console.error('Edit error:', error);
    alert('فشل تحديث المستخدم');
  }
};

const deleteUser = async (userId) => {
  if (!confirm('هل أنت متأكد من حذف المستخدم؟')) return;
  try {
    await apiClient.get(`/users/${userId}`);
    alert('تم حذف المستخدم بنجاح');
    fetchUsers();
  } catch (error) {
    console.error('Delete error:', error);
    alert('فشل حذف المستخدم');
  }
};

onMounted(fetchUsers);
</script>

<style scoped>
.user-list {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}

::v-deep(.baseinput-core) {
  padding: 8px !important;
}
.eye-icon {
  right: 25px;
  top: 56%;
  cursor: pointer;
}
.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

.table th {
  background: #B38F4B;
  color: white;
}
</style>