import { reactive, watchEffect } from 'vue';

export function useUser() {
    // Load user state from localStorage
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role") || ''
    const companyId = localStorage.getItem("companyId") || ''
    // Reactive user state
    const user = reactive({
        isAuthenticated: token ? true : false,
        userType: role || 'user',
        companyId: companyId || ''
    });

    // Method to update authentication status
    function setAuthenticated(status) {
        user.isAuthenticated = status;
    }

    // Method to update user type and persist it
    function setUser(data) {
        user.isAuthenticated = true;
        user.userType = data.role;
        localStorage.setItem("user", JSON.stringify(data));
        localStorage.setItem("token", data.token);
        localStorage.setItem("companyId", data.companyId);
    }

    // Method to log out and clear storage
    function logout() {
        user.isAuthenticated = false;
        user.userType = null;
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        window.location.reload(); // Reload to reflect logout
    }

    // Keep localStorage updated when user changes
    watchEffect(() => {
        if (user.isAuthenticated) {
            localStorage.setItem("user", JSON.stringify({ role: user.userType }));
        }
    });

    return {
        user,
        setAuthenticated,
        setUser,
        logout,
    };
}
