<template>
  <UserLayout>
    <div class="contact-page container py-5">
      <div class="page-content">

      <h2 class="text-center mb-4">تواصل معنا</h2>

      <p class="text-center mb-4 lead">
        مرحباً بك في صفحة <strong>تواصل معنا</strong>. نحن هنا للإجابة على أي استفسارات قد تكون لديك.
      </p>

      <div class="row mb-5">
        <div class="col-md-4 text-center">
          <h5>📞 رقم الهاتف</h5>
          <p>+963 999 999 999</p>
        </div>
        <div class="col-md-4 text-center">
          <h5>✉️ البريد الإلكتروني</h5>
          <p>info@syria14.com</p>
        </div>
        <div class="col-md-4 text-center">
          <h5>📍 عنوان المكتب الرئيسي</h5>
          <p>سوريا - دمشق - شارع الثورة</p>
        </div>
      </div>

      <div class="contact-form mx-auto" style="max-width: 600px;">
        <h4 class="text-center mb-3">نموذج الاتصال</h4>
        <form @submit.prevent="submitForm">
          <div class="mb-3">
            <label class="form-label">البريد الإلكتروني</label>
            <input v-model="form.email" type="email" class="form-control" required />
          </div>
          <div class="mb-3">
            <label class="form-label">رقم الهاتف</label>
            <input v-model="form.phone"  inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control" @input="form.phone = form.phone.replace(/[^\d\u0660-\u0669]/g, '')" required />
          </div>
          <div class="mb-3">
            <label class="form-label">الرسالة</label>
            <textarea v-model="form.message" class="form-control" rows="5" required></textarea>
          </div>
          <div class="text-center">
            <button class="btn btn-primary px-5" type="submit">📤 إرسال</button>
          </div>
        </form>
      </div>
      </div>

    </div>
  </UserLayout>
</template>

<script setup>
import { ref } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";
import apiClient from "@/services/apiClient";

const form = ref({
  email: '',
  phone: '',
  message: ''
});

const submitForm = async () => {
  try {
    await apiClient.post('/email/send', {
      email: form.value.email,
      phoneNumber: form.value.phone,
      message: form.value.message,
    });
    alert('✅ تم إرسال رسالتك بنجاح. شكرًا لتواصلك معنا!');
    form.value = { email: '', phone: '', message: '' };
  } catch (error) {
    console.error('❌ فشل في إرسال الرسالة:', error.response?.data || error.message);
    alert('حدث خطأ أثناء الإرسال، يرجى المحاولة لاحقاً.');
  }
};

</script>

<style scoped>
.contact-page {
  min-height: calc(100vh - 370px);
}

.page-content{
  margin-top: 75px;
}
.contact-form .form-control {
  border-radius: 10px;
  border: 1px solid #ccc;
}

.contact-form button {
  background-color: #B38F4B;
  border: none;
  border-radius: 10px;
}
</style>
