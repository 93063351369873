<template>
  <div class="admin-users">
    <h2>📋 قائمة المستخدمين الإداريين</h2>

    <table class="table">
      <thead>
        <tr>
          <th>#</th>
          <th>الاسم</th>
          <th>إجمالي الشقق</th>
          <th>الإعلانات</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in adminUsers" :key="user.id">
          <td>{{ index + 1 }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.houseCount }}</td>
          <td>
            <!-- if houseCount > 0 -->
            <button v-if="user.houseCount > 0" @click="viewUserHouses(user.id)" class="view-houses-btn">
              👀 رؤية جميع الإعلانات
            </button>
            <!-- <button @click="viewUserHouses(user.id)" class="view-houses-btn">
              👀 رؤية جميع الإعلانات
            </button> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import apiClient from "@/services/apiClient";

const adminUsers = ref([]);
const router = useRouter();

onMounted(async () => {
  try {
    const response = await apiClient.get('/company/houses/count');
    adminUsers.value = response.data;
  } catch (error) {
    console.error("خطأ في جلب بيانات المستخدمين الإداريين", error);
  }
});

const viewUserHouses = (userId) => {
  router.push(`/company/${userId}/houses`);
};
</script>

<style scoped>
.admin-users {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.view-houses-btn {
  background-color: #B38F4B;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

.view-houses-btn:hover {
  background-color: #a07a3e;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.table th {
  background: #B38F4B;
  color: white;
}
</style>
