<template>
  <div class="requested-apartments-container">
    <h2 class="text-center mb-4">طلبات الحجز</h2>

    <!-- 🔹 Status Filter Buttons -->
    <div class="btn-group mb-3" role="group">
      <button
          v-for="s in statusOptions"
          :key="s.value"
          class="btn"
          :class="selectedStatus === s.value ? 'btn-primary' : 'btn-outline-primary'"
          @click="changeStatusFilter(s.value)"
      >
        {{ s.label }}
      </button>
    </div>

    <!-- 🔍 Search Input -->
    <div class="search-container mb-3">
      <input type="text" v-model="searchQuery" class="form-control" placeholder="🔍 ابحث عن طلب..." />
    </div>

    <!-- 📋 Table of Requests -->
    <table class="table table-striped">
      <thead>
      <tr>
        <th>رقم الحجز</th>
        <th>رقم المنزل</th>
        <th>اسم المستخدم</th>
        <th>رقم الهاتف</th>
        <th>من تاريخ</th>
        <th>إلى تاريخ</th>
        <th>الحالة</th>
        <th>الإجراءات</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="request in filteredRequests" :key="request.houseBookingId">
        <td>{{ request.houseBookingId }}</td>
        <td>{{ request.houseId }}</td>
        <td>{{ request.userFullName }}</td>
        <td>{{ request.userPhoneNumber }}</td>
        <td>{{ request.dateFrom }}</td>
        <td>{{ request.dateTo }}</td>
        <td>
          <span :class="`status-badge ${getStatusClass(request.status)}`">{{ getStatusLabel(request.status) }}</span>
        </td>
        <td v-if="selectedStatus==='PENDING'">
          <button @click="approveRequest(request.houseBookingId)" class="btn btn-success btn-sm me-2">✔ موافقة</button>
          <button @click="rejectRequest(request.houseBookingId)" class="btn btn-danger btn-sm">✖ رفض</button>
        </td>
        <td v-if="selectedStatus==='APPROVED'">
          <button @click="rejectRequest(request.houseBookingId)" class="btn btn-danger btn-sm">✖ إلغاء</button>
        </td>
        <td v-if="selectedStatus==='REJECTED'">
          <button @click="approveRequest(request.houseBookingId)" class="btn btn-success btn-sm me-2">✔ موافقة</button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import apiClient from '@/services/apiClient';

const searchQuery = ref('');
const requests = ref([]);

const statusOptions = [
  { label: 'قيد المراجعة', value: 'PENDING' },
  { label: 'تم القبول', value: 'APPROVED' },
  { label: 'تم الرفض', value: 'REJECTED' },
];

const selectedStatus = ref('PENDING');

const fetchRequests = async () => {
  try {
    const response = await apiClient.get(`/house/bookings/status/${selectedStatus.value}`);
    requests.value = response.data;
  } catch (error) {
    console.error('Failed to fetch requests:', error);
  }
};

onMounted(() => {
  fetchRequests();
});

const changeStatusFilter = (status) => {
  selectedStatus.value = status;
  fetchRequests();
};

const filteredRequests = computed(() => {
  return requests.value.filter(request =>
      request.userPhoneNumber.includes(searchQuery.value) ||
      request.dateFrom.includes(searchQuery.value) ||
      request.dateTo.includes(searchQuery.value)
  );
});

const getStatusClass = (status) => {
  switch (status) {
    case 'PENDING': return 'pending';
    case 'APPROVED': return 'approved';
    case 'REJECTED': return 'rejected';
    case 'BOOKED': return 'booked';
    default: return '';
  }
};

const getStatusLabel = (status) => {
  switch (status) {
    case 'PENDING':
      return 'قيد المراجعة';
    case 'APPROVED':
      return 'تم القبول';
    case 'REJECTED':
      return 'تم الرفض';
    case 'BOOKED':
      return 'محجوز';
    default:
      return status;
  }
};

const updateStatus = async (id, status) => {
  try {
    await apiClient.post('/house/bookings/status', {
      houseBookingId: id,
      status: status
    });

    const request = requests.value.find(r => r.houseBookingId === id);
    if (request) request.status = status;
    window.location.reload();
  } catch (error) {
    console.error(`Failed to update status to ${status}:`, error);
    alert('حدث خطأ أثناء تحديث الحالة');
  }
};

const approveRequest = (id) => updateStatus(id, 'APPROVED');
const rejectRequest = (id) => updateStatus(id, 'REJECTED');
// const markAsBooked = (id) => updateStatus(id, 'BOOKED');
</script>

<style scoped>
.requested-apartments-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-container input {
  max-width: 300px;
  margin-bottom: 20px;
}

.status-badge {
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.status-badge.pending { background-color: #ffc107; color: white; }
.status-badge.approved { background-color: #28a745; color: white; }
.status-badge.rejected { background-color: #dc3545; color: white; }
.status-badge.booked { background-color: #17a2b8; color: white; }

button {
  margin: 5px;
}
</style>
