<template>
  <div class="pending-users">
    <h2>⏳ طلبات المستخدمين المعلقة</h2>
    <table class="table">
      <thead>
      <tr>
        <th>#</th>
        <th>اسم الشركة</th>
        <th>العنوان</th>
        <th>رقم الهاتف</th>
        <th>الوصف</th>
        <th>الحالة</th>
        <th>الصور</th>
        <th>الإجراءات</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(user, index) in pendingUsers" :key="user.id">
        <td>{{ index + 1 }}</td>
        <td>{{ user.name }}</td>
        <td class="ellipsis">{{ user.address }}</td>
        <td>{{ user.phoneNumber }}</td>
        <td class="ellipsis">{{ user.description }}</td>
        <td>{{ user.status }}</td>
        <td>
          <div class="image-container">
            <div class="image-scroll">
              <img
                  v-for="file in user.files"
                  :key="file.id"
                  :src="file.imageUrl"
                  class="company-image"
                  @click="openImage(file.imageUrl)"
              />
            </div>
            <span v-if="user.files.length === 0">لا توجد صور</span>
          </div>
        </td>
        <td>
          <button @click="openModal(user.id)" class="approve-btn">✔ قبول</button>
          <button @click="changeCompanyStatus(user.id, 'INACTIVE')" class="reject-btn">✖ رفض</button>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Approval Modal -->
    <div class="modal fade" id="approveModal" tabindex="-1" aria-labelledby="approveModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitApproval">
            <div class="modal-header">
              <h5 class="modal-title">تأكيد القبول</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <label class="form-label">تاريخ انتهاء الصلاحية</label>
              <input type="date" v-model="selectedValidUntilDate" class="form-control" required />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">إلغاء</button>
              <button type="submit" class="btn btn-primary">تأكيد</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import apiClient from "@/services/apiClient";
import { Modal } from 'bootstrap';

const pendingUsers = ref([]);
const selectedCompanyId = ref(null);
const selectedValidUntilDate = ref(null);
let approveModalInstance = null;

onMounted(async () => {
  try {
    const response = await apiClient.get("/companies/PENDING");
    pendingUsers.value = response.data;
    approveModalInstance = new Modal(document.getElementById('approveModal'));
  } catch (error) {
    console.error("Error fetching users:", error.response?.data || error.message);
  }
});

const openImage = (imageUrl) => {
  window.open(imageUrl, "_blank");
};

const openModal = (companyId) => {
  selectedCompanyId.value = companyId;
  const today = new Date();
  const nextYear = new Date(today.setFullYear(today.getFullYear() + 1)).toISOString().split("T")[0];
  selectedValidUntilDate.value = nextYear;
  approveModalInstance.show();
};

const submitApproval = async () => {
  try {
    await apiClient.post("/company/changeStatus", {
      companyId: selectedCompanyId.value,
      status: "ACTIVE",
      validUntil: selectedValidUntilDate.value
    });

    alert(`تم قبول المستخدم ${selectedCompanyId.value}`);
    pendingUsers.value = pendingUsers.value.filter(user => user.id !== selectedCompanyId.value);
    approveModalInstance.hide();
  } catch (error) {
    console.error("Error approving company:", error.response?.data || error.message);
    alert("حدث خطأ أثناء تحديث الحالة، حاول مرة أخرى.");
  }
};

const changeCompanyStatus = async (companyId, status) => {
  try {
    await apiClient.post("/company/changeStatus", {
      companyId,
      status
    });

    alert(`تم ${status === "ACTIVE" ? "قبول" : "رفض"} المستخدم ${companyId}`);
    pendingUsers.value = pendingUsers.value.filter(user => user.id !== companyId);
  } catch (error) {
    console.error(`Error changing status for ${companyId}:`, error.response?.data || error.message);
    alert("حدث خطأ أثناء تحديث الحالة، حاول مرة أخرى.");
  }
};
</script>

<style scoped>
.ellipsis {
  max-width: 200px;        /* or any fixed width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pending-users {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 12px;
  text-align: center;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

.table th {
  background: #B38F4B;
  color: white;
}

.image-container {
  max-width: 200px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.image-scroll {
  display: flex;
  gap: 5px;
}

.company-image {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s;
}

.company-image:hover {
  transform: scale(1.1);
}

.image-container::-webkit-scrollbar {
  height: 6px;
}

.image-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

.image-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.image-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.approve-btn {
  background: #28a745;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 5px;
}

.approve-btn:hover {
  background: #218838;
}

.reject-btn {
  background: #dc3545;
  color: white;
  padding: 6px 12px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
}

.reject-btn:hover {
  background: #c82333;
}

@media (max-width: 768px) {
  .table {
    font-size: 14px;
  }

  .approve-btn, .reject-btn {
    padding: 4px 8px;
    font-size: 12px;
  }
}
</style>