<template>
  <div class="company-list">
    <h2>🏢 قائمة الوكلاء العقاريين</h2>
    <table class="table">
      <thead>
      <tr>
        <th>#</th>
        <th>الاسم</th>
        <th>العنوان</th>
        <th>رقم الهاتف</th>
        <th>الوصف</th>
        <th>الصور</th>
        <th>الإجراءات</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(company, index) in companies" :key="company.id">
        <td>{{ index + 1 }}</td>
        <td>{{ company.name }}</td>
        <td class="ellipsis">{{ company.address }}</td>
        <td>{{ company.phoneNumber }}</td>
        <td class="ellipsis">{{ company.description }}</td>
        <td>
          <div class="d-flex flex-wrap gap-2">
            <img v-for="img in company.files" :key="img.id" :src="img.imageUrl" class="img-thumbnail company-img" />
            <span v-if="company.files.length === 0">لا صور</span>
          </div>
        </td>
        <td>
          <button class="btn btn-primary btn-sm me-1" @click="openEditModal(company)">✏ تعديل</button>
          <button
              v-if="company.status === 'ACTIVE'"
              class="btn btn-warning btn-sm"
              @click="changeCompanyStatus(company.id, 'INACTIVE', new Date().toISOString().split('T')[0])"
          >
            🔒 تعطيل
          </button>
          <button
              v-else
              class="btn btn-success btn-sm"
              @click="openApproveModal(company.id)"
          >
            ✅ تفعيل
          </button>
        </td>

      </tr>
      </tbody>
    </table>

    <!-- Edit Modal -->
    <div class="modal fade" id="editCompanyModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <form @submit.prevent="submitEdit">
            <div class="modal-header">
              <h5 class="modal-title">تعديل الشركة</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
            </div>
            <div class="modal-body">
              <div class="row g-3">
                <div class="col-md-6">
                  <label class="form-label">الاسم</label>
                  <input type="text" class="form-control" v-model="editCompany.name" required />

                  <label class="form-label mt-2">رقم الهاتف</label>
                  <phone-input
                      :key="editCompany.id"
                      @phoneData="(e)=>editCompany.phoneNumber = e.number"
                      :value="Number(editCompany.phoneNumber)"
                      :allowed=countries
                  />
                  <label class="form-label mt-2">العنوان</label>
                  <input type="text" class="form-control" v-model="editCompany.address" required />

                  <label class="form-label mt-2">تاريخ الانتهاء</label>
                  <input type="date" class="form-control" v-model="editCompany.validUntil" />

                  <label class="form-label mt-3">📤 رفع صور جديدة</label>
                  <input type="file" multiple @change="uploadImages" class="form-control" accept="image/*" />
                </div>
                <div class="col-md-6">
                  <label class="form-label">الوصف</label>
                  <textarea class="form-control" rows="10" v-model="editCompany.description"></textarea>

                  <h6 class="mt-3">📷 الصور الحالية</h6>
                  <div class="d-flex flex-wrap gap-2">
                    <div v-for="img in editCompany.files" :key="img.id" class="position-relative">
                      <img :src="img.imageUrl" class="img-thumbnail company-img" />
                      <button
                          type="button"
                          class="btn btn-sm btn-danger position-absolute top-0 end-0"
                          @click="deleteImage(img.id)"
                      >×</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">إلغاء</button>
              <button type="submit" class="btn btn-success">💾 حفظ التعديلات</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="modal fade" id="approveModal" tabindex="-1" aria-labelledby="approveModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <form @submit.prevent="submitApproval">
            <div class="modal-header">
              <h5 class="modal-title">تأكيد القبول</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <label class="form-label">تاريخ انتهاء الصلاحية</label>
              <input type="date" v-model="selectedValidUntilDate" class="form-control" required />
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">إلغاء</button>
              <button type="submit" class="btn btn-primary">تأكيد</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { Modal } from 'bootstrap';
import apiClient from '@/services/apiClient';
import {countries} from "@/iso";
import { PhoneInput } from '@lbgm/phone-number-input';
import '@lbgm/phone-number-input/style';

const companies = ref([]);
const editCompany = ref({ id: null, name: '', address: '', phoneNumber: '', description: '', validUntil: '', files: [] });

const fetchCompanies = async () => {
  try {
    const response = await apiClient.get('/companies');
    companies.value = response.data;
  } catch (error) {
    console.error('Error fetching companies:', error);
  }
};

const openEditModal = (company) => {
  editCompany.value = {
    id: company.id,
    name: company.name,
    address: company.address,
    phoneNumber: company.phoneNumber,
    description: company.description,
    validUntil: company.validUntil?.split('T')[0] || '',
    files: company.files || []
  };
  const modal = new Modal(document.getElementById('editCompanyModal'));
  modal.show();
};

const submitEdit = async () => {
  try {
    await apiClient.post('/company/update', {
      id: editCompany.value.id,
      name: editCompany.value.name,
      address: editCompany.value.address,
      phoneNumber: editCompany.value.phoneNumber,
      description: editCompany.value.description,
      validUntil: editCompany.value.validUntil
    });
    alert('✅ تم تحديث معلومات الشركة');
    Modal.getInstance(document.getElementById('editCompanyModal')).hide();
    fetchCompanies();
  } catch (error) {
    console.error('Error updating company:', error);
    alert('❌ فشل في تحديث الشركة');
  }
};

const uploadImages = async (event) => {
  const files = event.target.files;
  if (!files.length) return;

  try {
    for (const file of files) {
      const formData = new FormData();
      formData.append('companyId', editCompany.value.id);
      formData.append('image', file);
      await apiClient.post('/company/image/create', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      });
    }
    alert('✅ تم رفع الصور بنجاح');
    fetchCompanies();
  } catch (error) {
    console.error('❌ فشل رفع الصور:', error);
    alert('فشل رفع الصور');
  }
};

const deleteImage = async (imageId) => {
  if (!confirm('هل تريد حذف هذه الصورة؟')) return;
  try {
    await apiClient.get(`/company/image/delete/${imageId}`);
    editCompany.value.files = editCompany.value.files.filter(img => img.id !== imageId);
    alert('✅ تم حذف الصورة');
  } catch (error) {
    console.error('Error deleting image:', error);
    alert('❌ فشل في حذف الصورة');
  }
};


const selectedCompanyId = ref(null);
const selectedValidUntilDate = ref('');

const openApproveModal = (companyId) => {
  selectedCompanyId.value = companyId;
  selectedValidUntilDate.value = '';
  const modal = new Modal(document.getElementById('approveModal'));
  modal.show();
};

const submitApproval = async () => {
  try {
    const payload = {
      companyId: selectedCompanyId.value,
      status: "ACTIVE",
      validUntil: selectedValidUntilDate.value
    };
    await apiClient.post("/company/changeStatus", payload);
    alert("✅ تم تفعيل الشركة");
    Modal.getInstance(document.getElementById('approveModal')).hide();
    fetchCompanies();
  } catch (error) {
    console.error('Error approving company:', error);
    alert("❌ فشل في تفعيل الشركة");
  }
};

const changeCompanyStatus = async (companyId, status, validUntil) => {
  try {
    await apiClient.post("/company/changeStatus", {
      companyId,
      status,
      validUntil
    });
    alert(`✅ تم تغيير الحالة إلى ${status}`);
    fetchCompanies();
  } catch (error) {
    console.error('Status change error:', error);
    alert("❌ فشل في تغيير حالة الشركة");
  }
};


  // const deleteCompany = async (id) => {
  //   if (!confirm('هل تريد حذف هذه الشركة؟')) return;
  //   try {
  //     await apiClient.get(`/company/delete/${id}`);
  //     alert('✅ تم حذف الشركة بنجاح');
  //     fetchCompanies();
  //   } catch (error) {
  //     console.error('Error deleting company:', error);
  //     alert('❌ فشل في حذف الشركة');
  //   }
  // };

onMounted(fetchCompanies);
</script>

<style scoped>
.company-list {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
}

.table th, .table td {
  text-align: center;
  vertical-align: middle;
}

::v-deep(.baseinput-core) {
  padding: 8px !important;
}

.company-img {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
}
.ellipsis {
  max-width: 200px;        /* or any fixed width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
