<template>
  <div class="create-apartment-container">
    <h2 class="text-center mb-4">{{ isEditMode ? 'تعديل شقة' : 'إنشاء شقة جديدة' }}</h2>
    <h5 style="float: right;">من فضلك لا تقم بتعبئة عدد الحمامات، عدد الغرف، الطابق، الفرش، أو روابط أخرى إذا لم تكن ضرورية</h5>
    <form @submit.prevent="submitApartment" class="form-container">

      <div class="mb-3">
        <label for="district" class="form-label"> المحافظة</label>
        <select v-model="apartment.districtId" class="form-select" id="district" required @change="fetchCities">
          <option value="">اختر المحافظة</option>
          <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="city" class="form-label">المنطقة</label>
        <select v-model="apartment.cityId" class="form-select" id="city" required>
          <option value="">اختر المنطقة</option>
          <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="houseType" class="form-label">نوع العقار</label>
        <select v-model="apartment.houseTypeId" class="form-select" id="houseType" required>
          <option value="">اختر النوع</option>
          <option v-for="type in houseTypes" :key="type.id" :value="type.id">{{ type.type }}</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="listingType" class="form-label">نوع العرض</label>
        <select v-model="apartment.listingType" class="form-select" id="listingType">
          <option value="RENT">إيجار</option>
          <option value="BUY">بيع</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="description" class="form-label">الوصف</label>
        <textarea v-model="apartment.description" class="form-control" id="description" rows="3" required placeholder="أدخل وصف الشقة"></textarea>
      </div>

      <div class="mb-3">
        <label for="price" class="form-label">السعر (ل.س)</label>
        <input v-model="apartment.price" type="text" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="apartment.price = apartment.price.replace(/[^\d\u0660-\u0669]/g, '')" id="price" required placeholder="أدخل السعر" />
      </div>

      <div class="mb-3">
        <label for="roomsNumber" class="form-label">عدد الغرف</label>
        <input v-model="apartment.roomsNumber" type="text" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="apartment.roomsNumber = apartment.roomsNumber.replace(/[^\d\u0660-\u0669]/g, '')" id="roomsNumber" placeholder="أدخل عدد الغرف" min="1" />
      </div>

      <div class="mb-3">
        <label for="address" class="form-label">العنوان</label>
        <input v-model="apartment.address" type="text" class="form-control" id="address" required placeholder="أدخل العنوان" />
      </div>

      <div class="mb-3">
        <label for="mapsLink" class="form-label">رابط الموقع على الخريطة</label>
        <input v-model="apartment.mapsLink" type="text" class="form-control" id="mapsLink" required placeholder="أدخل رابط الموقع على الخريطة" />
      </div>

      <div class="mb-3">
        <label for="bathroomNumber" class="form-label">عدد الحمامات</label>
        <input v-model="apartment.bathroomNumber" type="text" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="apartment.bathroomNumber = apartment.bathroomNumber.replace(/[^\d\u0660-\u0669]/g, '')" id="bathroomNumber" placeholder="أدخل عدد الحمامات" min="1" />
      </div>

      <div class="mb-3">
        <label for="area" class="form-label">مساحة العقار (م²)</label>
        <input v-model="apartment.area" type="text" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="apartment.area = apartment.area.replace(/[^\d\u0660-\u0669]/g, '')" id="area" required placeholder="أدخل مساحة العقار بالمتر المربع" min="1" />
      </div>

      <div class="mb-3">
        <!-- <label for="floor" class="form-label">الطابق</label> -->
        <select v-model="apartment.floor" class="tabek">
          <option value="-1">الطابق</option>
          <option value="0">طابق أرضي</option>
          <option v-for="n in 13" :key="n" :value="n">{{ n }}</option>
        </select>
        <!-- <input
            v-model="apartment.floor"
            type="text"
            inputmode="numeric"
            pattern="[0-9\u0660-\u0669]*"
            class="form-control"
            id="floor"
            max="13"
            placeholder="أدخل رقم الطابق"
            @input="apartment.floor = apartment.floor.replace(/[^\d\u0660-\u0669]/g, '')"
        /> -->
      </div>

      <div class="mb-3">
        <label for="otherLinks" class="form-label">رابط خارجي (مثال: يوتيوب أو فايسبوك)</label>
        <input
            v-model="apartment.otherLinks"
            type="url"
            class="form-control"
            id="otherLinks"
            placeholder="أدخل رابط خارجي"
        />
      </div>


      <div class="mb-3">
        <label for="furnished" class="form-label">مواصفات</label>
        <select v-model="apartment.furnished" class="form-select" id="furnished">
          <option :value="null">لا شيء</option>
          <option :value="true">مفروشة</option>
          <option :value="false">غير مفروشة</option>
        </select>
      </div>

      <div class="mb-3">
        <label for="files" class="form-label">صور العقار (10 صور كحد أقصى)</label>
        <input max="10" type="file" class="form-control" id="files" accept="image/*" multiple @change="handleFileUpload" />
        <div v-if="apartment.files.length" class="image-preview">
          <img v-for="(image, index) in previewImages" :key="index" :src="image" class="thumbnail" />
        </div>
      </div>

      <button type="submit" class="btn btn-primary w-100" :disabled="loading">
        {{ loading ? 'جارٍ الإرسال...' : (isEditMode ? 'تعديل' : 'إنشاء') }}
      </button>
    </form>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import apiClient from "@/services/apiClient";

const loading = ref(false);
const isEditMode = ref(false);
const houseTypes = ref([]);
const districts = ref([]);
const cities = ref([]);
const previewImages = ref([]);

const apartment = ref({
  cityId: '',
  listingType: '',
  description: '',
  houseTypeId: '',
  price: '',
  companyId: 1,
  roomsNumber: '',
  bathroomNumber: '',
  area: '',
  floor: -1,
  address: '',
  mapsLink: '',
  otherLinks: '', // ✅ renamed from externalLink
  files: [],
  furnished: null,
});


// Fetch house types
const fetchHouseTypes = async () => {
  try {
    const response = await apiClient.get("/houseTypes");
    houseTypes.value = response.data;
  } catch (error) {
    console.error("Error fetching house types:", error.response?.data || error.message);
  }
};

// Fetch districts
const fetchDistricts = async () => {
  try {
    const response = await apiClient.get("/districts");
    districts.value = response.data;
  } catch (error) {
    console.error("Error fetching districts:", error.response?.data || error.message);
  }
};

// Fetch cities based on selected district
const fetchCities = async () => {
  if (!apartment.value.districtId) return;
  try {
    const response = await apiClient.get(`/districts/${apartment.value.districtId}/cities`);
    cities.value = response.data;
  } catch (error) {
    console.error("Error fetching cities:", error.response?.data || error.message);
  }
};

// Handle file upload
const handleFileUpload = (event) => {
  const files = Array.from(event.target.files);
  if (files.length + apartment.value.files.length > 10) {
    alert("يمكنك تحميل 10 صور كحد أقصى");
    event.target.value = ""; // Clear the selected files

    return;
  }
  apartment.value.files.push(...files);
  previewImages.value.push(...files.map(file => URL.createObjectURL(file)));
};

// Submit apartment data
const submitApartment = async () => {
  if (confirm("هل تريد تأكيد إضافة العقار؟")) {
    loading.value = true;
    console.log(apartment.value, 'apartment');

    try {
      const formData = new FormData();
      formData.append("cityId", apartment.value.cityId);
      formData.append("listingType", apartment.value.listingType);
      formData.append("description", apartment.value.description);
      formData.append("houseTypeId", apartment.value.houseTypeId);
      formData.append("price", apartment.value.price);
      formData.append("companyId", localStorage.getItem('companyId'));
      formData.append("address", apartment.value.address);
      formData.append("mapsLink", apartment.value.mapsLink);
      formData.append("area", apartment.value.area);
      formData.append("status", localStorage.getItem('role') === 'ROLE_CUSTOMER_ADMIN' ? 'ACTIVE' : 'PENDING');

      // Optional fields (only if they have values)
      if (apartment.value.roomsNumber != null && apartment.value.roomsNumber !== '') {
        formData.append("roomsNumber", apartment.value.roomsNumber);
      }

      if (apartment.value.bathroomNumber != null && apartment.value.bathroomNumber !== '') {
        formData.append("bathroomNumber", apartment.value.bathroomNumber);
      }

      if (apartment.value.floor != null && apartment.value.floor !== '') {
        formData.append("floor", apartment.value.floor);
      }

      if (apartment.value.furnished != null && apartment.value.furnished !== '') {
        formData.append("furnished", apartment.value.furnished);
      }

      if (apartment.value.otherLinks && apartment.value.otherLinks.trim() !== '') {
        formData.append("otherLinks", apartment.value.otherLinks);
      }

      // Append files
      apartment.value.files.forEach((file, index) => {
        formData.append(`files[${index}]`, file);
      });

      const response = await apiClient.post("/house", formData, {
        headers: { "Content-Type": "multipart/form-data" }
      });

      console.log(response);
      alert("تم إنشاء العقار بنجاح!");
    } catch (error) {
      console.error("Error submitting apartment:", error.response?.data || error.message);
      alert("حدث خطأ أثناء إضافة العقار.");
    } finally {
      loading.value = false;
    }
  }
};

// Fetch initial data
onMounted(() => {
  fetchHouseTypes();
  fetchDistricts();
});
</script>

<style scoped>
.create-apartment-container {
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
}

.image-preview {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}

.image-preview img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 5px;
}

.tabek {
  padding: 10px;
  border-radius: 12px;
  border: none;
  background-color: rgba(255,255,255,0.6);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}


</style>
