<template>
  <div class="homepage">
  <UserLayout>
    <div class="container mt-5 bg-section py-5">
      <div class="row justify-content-center">
        <!-- 🔹 Bootstrap Image Carousel -->
        <div class="col-lg-8">
          <div id="houseCarousel" class="carousel slide shadow rounded" data-bs-ride="carousel">
            <div class="carousel-inner">
              <div
                  v-for="(img, index) in house.images"
                  :key="index"
                  class="carousel-item"
                  :class="{ active: index === 0 }"
              >
                <img :src="img" class="d-block w-100 carousel-image" alt="House Image" />
              </div>
            </div>

            <!-- 🔹 Carousel Controls -->
            <button class="carousel-control-prev" type="button" data-bs-target="#houseCarousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#houseCarousel" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </button>
          </div>
        </div>

        <!-- 🔹 Thumbnails Below the Carousel -->
        <div class="col-lg-8 mt-3 d-flex justify-content-center">
          <div class="thumbnail-container">
            <img
                v-for="(img, index) in house.images"
                :key="index"
                :src="img"
                class="img-thumbnail me-2 rounded"
                :class="{ active: selectedImage === img }"
                @click="changeCarouselImage(index)"
                alt="Thumbnail"
            />
          </div>
        </div>

        <!-- 🔹 Property Details Section -->
        <div class="col-lg-8 mt-4">
          <div class="details-box p-4 rounded shadow-sm bg-white">
            <h2 class="fw-bold">{{ house.title }}</h2>
            <p class="text-muted">📍 {{ house.city }} - {{ house.district }}</p>
            <h3 class="price-text fw-bold">{{ house.price }} ل.س</h3>

            <div class="details-section">
              <p><strong>🏠 نوع العقار:</strong> {{ house.propertyType }}</p>
              <p v-if="house.rooms !==null"><strong>🛏️ عدد الغرف:</strong> {{ house.rooms }}</p>
              <p><strong>📝 الوصف:</strong> {{ house.description }}</p>
              <p v-if="house.floor !== null">
              <strong>🪜 الطابق:</strong> {{ house.floor === 0 ? 'الأرضي' : house.floor }}
              </p>

              <p v-if="house.furnished !==null"><strong>🛋 مفروش:</strong> {{ house.furnished ? 'نعم' : 'لا' }}</p>
              <p v-if="house.otherLinks" class="text-muted small">
                🔗 <a :href="house.otherLinks" target="_blank">رابط إضافي</a>
              </p>
              <hr />
              <h5 class="fw-bold">🗂 تفاصيل الشركة</h5>
              <p><strong>🏢 اسم الشركة:</strong> {{ house.companyName || "غير متوفر" }}</p>
              <p><strong>📞 رقم الهاتف:</strong> {{ house.companyPhoneNumber || "غير متوفر" }}</p>
            </div>
            <div v-if="house?.mapsLink">
              <iframe
                  :src="`https://www.google.com/maps?q=${latitude},${longitude}&z=15&output=embed`"
                  width="100%"
                  height="400"
                  style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
              ></iframe>
            </div>


            <!-- 🔹 Contact Buttons -->
            <div v-if="house.listingType=='RENT'" class="d-flex flex-column mt-3">
              <VueDatePicker   placeholder="اختر الفترة" :range="{ noDisabledRange: true }" :disabled-dates="disabledDates" v-model="date"></VueDatePicker>
              <button  @click="bookHouse" class="btn custom-btn btn-primary me-2 mt-5">📞 حجز</button>
            </div>
            <template v-else>
            <div class="d-flex flex-column mt-3">
              <a
                :href="`tel:${house.companyPhoneNumber}`"
                class="btn custom-btn btn-primary me-2 mt-5"
              >
                📞 تواصل
              </a>
            </div>
          </template>
          </div>
        </div>
      </div>
    </div>
  </UserLayout>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import { useRoute } from 'vue-router';
import apiClient from "@/services/apiClient";
import UserLayout from "@/layouts/UserLayout.vue";
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import {useUser} from "@/composables/useUser";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const house = ref({});
const selectedImage = ref(null);
const route = useRoute();
const latitude = ref(null);
const longitude = ref(null);

import { eachDayOfInterval, parseISO } from 'date-fns';
import {Modal} from "bootstrap";

const date = ref([]);
const disabledDates = ref([]);

const generateDisabledDates = (bookings) => {
  const disabled = [];

  bookings.forEach(booking => {
    const from = parseISO(booking.dateFrom);
    const to = parseISO(booking.dateTo);

    const days = eachDayOfInterval({ start: from, end: to });
    disabled.push(...days);
  });

  return disabled;
};

// 🔥 Fetch House Details
const fetchHouseDetails = async () => {
  try {
    const response = await apiClient.get(`/house/${route.params.id}`);
    const data = response.data;

    house.value = {
      houseId: data.houseId,
      title: data.address || "عنوان غير متوفر",
      city: data.city?.name || "مدينة غير معروفة",
      district: data.city?.district?.name || "حي غير معروف",
      listingType: data.listingType,
      bookings: data.bookinsg || [], // just in case it's missing
      description: data.description || "لا يوجد وصف",
      images: data.images?.map(img => img.imageUrl) || [],
      propertyType: data.houseType?.type || "نوع غير معروف",
      rooms: data.roomsNumber,
      price: data.price,
      mapsLink: data.mapsLink,
      companyName: data.companyName || "",
      companyPhoneNumber: data.companyPhoneNumber || "",
      status: data.status || "غير متوفر",
      floor: data.floor,
      furnished: data.furnished,
      otherLinks: data.otherLinks || ''
    };

    // ✅ Generate disabled dates AFTER bookings are loaded
    disabledDates.value = generateDisabledDates(house.value.bookings);

    // ✅ Set first image
    selectedImage.value = house.value.images.length > 0 ? house.value.images[0] : null;
  } catch (error) {
    console.error("Error fetching house details:", error);
  }
};

// 🔥 Change Image when Thumbnail Clicked
const changeCarouselImage = (index) => {
  const carousel = new bootstrap.Carousel(document.getElementById('houseCarousel'));
  carousel.to(index);
  selectedImage.value = house.value.images[index];
};

const extractCoordinates = () => {
  const match = house.value.mapsLink?.match(/@([0-9.-]+),([0-9.-]+)/);
  if (match) {
    latitude.value = parseFloat(match[1]);
    longitude.value = parseFloat(match[2]);
  }
  //  else {
  //   alert("Invalid Google Maps URL");
  // }
};


const openModal = () => {
  const modalElement = document.getElementById("signInModal");
  if (modalElement) {
    const myModal = new Modal(modalElement);
    myModal.show();
  }
};

const bookHouse = async () => {
  if (!date.value[0] || !date.value[1]) {
    alert('❗ يرجى تحديد تاريخ البداية والنهاية.');
    return;
  }
  if (!useUser().user.isAuthenticated){
    openModal();
    return;
  }
  try {
    const payload = {
      houseId: route.params.id,
      status: "PENDING",
      startDate: date.value[0].toISOString().split('T')[0], // Format as YYYY-MM-DD
      endDate: date.value[1].toISOString().split('T')[0],
    };

    await apiClient.post('/house/bookings', payload);
    alert('✅ تم ارسال طلب الحجز, سيتم التواصل معكم من الادارة للتاكيد قريباً.');
  } catch (error) {
    console.error(error);
    alert('❌ فشل الحجز، يرجى المحاولة لاحقاً.');
  }
};

// ✅ Fetch on page load
onMounted(async () => {
  await fetchHouseDetails();
  extractCoordinates();
});

</script>

<style scoped>
/* 🔥 Centered Container */
.container {
  max-width: 1100px;
}

.bg-section{
  margin-top: 140px !important;
  margin-bottom: 50px;
  //padding-top: 25px;
  background: rgba(255, 255, 255, 0.15);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(10px);
  border-radius: 12px;
}
/* 🔥 Bootstrap Carousel Image */
.carousel-image {
  width: 100%;
  height: 400px;
  object-fit: cover;
  border-radius: 10px;
}

/* 🔥 Thumbnail Images */
.thumbnail-container {
  display: flex;
  overflow-x: auto;
  max-width: 800px;
  gap: 10px;
}

.thumbnail-container img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  transition: 0.3s;
  border: 2px solid transparent;
}

.thumbnail-container img.active {
  border: 3px solid #28a745;
}
/* 🔥 Property Details Box */
.details-box {
  background: white;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* 🔥 Property Details Layout */
.details-section {
  text-align: right;
}

/* 🔥 Status Colors */
.text-success {
  color: green;
}

.text-danger {
  color: red;
}

.text-warning {
  color: orange;
}

.homepage {
  background-image: url('@/assets/back-test.png');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  height: 100vh;
  overflow-y:scroll ;
}

/* 🔥 Contact Buttons */
.btn {
  font-size: 18px;
  font-weight: bold;
}
.price-text{
  color: #3B412C !important;
}

.custom-btn {
  background-color: #B38F4B !important;
  border-color: #B38F4B !important;
}
.custom-btn:hover {
  transform: translateY(-2px);
}
</style>

<style>
.dp__cell_disabled{
  background: red !important;
}
.dp__range_end{
  opacity: 45% !important ;
}
.dp__range_start{
  opacity: 45% !important ;
}
.dp__range_between{
  opacity: 45% !important ;
}
.dp__cell_inner{
  color: white !important;
  background: green;
  opacity: 85% !important ;
}
</style>
