<template>
  <UserLayout>
    <div class="container mt-4">
      <h2>📅 حجوزاتي</h2>
      <div v-if="bookings.length === 0" class="alert alert-info">لم تقم بأي حجوزات حتى الآن.</div>
      <table v-else class="table table-bordered mt-3">
        <thead>
        <tr>
          <th>العقار</th>
          <th>التاريخ</th>
          <th>الحالة</th>
          <th>الإجراءات</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="booking in bookings" :key="booking.id">
          <td>{{ booking.houseTitle }}</td>
          <td>{{ booking.date }}</td>
          <td>
            <span :class="statusClass(booking.status)">{{ booking.status }}</span>
          </td>
          <td>
            <button class="btn btn-danger btn-sm" @click="cancelBooking(booking.id)" v-if="booking.status === 'قيد الانتظار'">إلغاء</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </UserLayout>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";

const bookings = ref([]);

onMounted(() => {
  bookings.value = JSON.parse(localStorage.getItem("bookings")) || [];
});

const cancelBooking = (id) => {
  bookings.value = bookings.value.filter(booking => booking.id !== id);
  localStorage.setItem("bookings", JSON.stringify(bookings.value));
  alert("تم إلغاء الحجز بنجاح.");
};

const statusClass = (status) => {
  return {
    'text-warning': status === 'قيد الانتظار',
    'text-success': status === 'مؤكد',
    'text-danger': status === 'مرفوض'
  };
};
</script>

<style scoped>
.container {
  padding-top: 20px;
}
</style>
