<template>
  <div class="create-subadmin-container">
    <h2 class="text-center mb-4">إنشاء مستخدم فرعي</h2>
    <form @submit.prevent="createSubAdmin" class="form-container">
      <div class="mb-3">
        <label for="username" class="form-label">اسم المستخدم</label>
        <input v-model="subAdmin.username" type="text" class="form-control" id="username" required placeholder="أدخل اسم المستخدم" />
      </div>
      <div class="mb-3">
        <label class="form-label">الاسم الكامل</label>
        <input v-model="subAdmin.fullName" type="text" class="form-control" required placeholder="أدخل اسم" />
      </div>

      <div class="mb-3">
        <label for="email" class="form-label">البريد الإلكتروني</label>
        <input v-model="subAdmin.email" type="email" class="form-control" id="email" required placeholder="أدخل البريد الإلكتروني" />
      </div>

      <div class="mb-3">
        <label for="password" class="form-label">كلمة المرور</label>
        <input v-model="subAdmin.password" type="password" class="form-control" id="password" required placeholder="أدخل كلمة المرور" />
      </div>

      <div class="mb-3">
        <label for="phoneNumber" class="form-label">رقم الهاتف</label>
        <phone-input
            @phoneData="(e)=>subAdmin.phoneNumber = e.number"
            :value="Number(subAdmin.phoneNumber)"
            :allowed=countries
            :default-country="'SY'"
        />
      </div>
      <button type="submit" class="btn btn-primary w-100">إنشاء</button>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import apiClient from "@/services/apiClient";
import { PhoneInput } from '@lbgm/phone-number-input';
import '@lbgm/phone-number-input/style';
import {countries} from "@/iso";

const subAdmin = ref({
  username: '',
  email: '',
  password: '',
  phoneNumber: '',
  role: ['ROLE_CUSTOMER_EMPLOYEE']
});


const createSubAdmin = async () => {
  try {
     await apiClient.post('/users/create', subAdmin.value);
    alert(`✅ تم إنشاء المستخدم الفرعي: ${subAdmin.value.username}`);
    // Reset form
    subAdmin.value = {
      username: '',
      fullName:'',
      email: '',
      password: '',
      phoneNumber: '',
      role: ['ROLE_CUSTOMER_EMPLOYEE']
    };
  } catch (error) {
    // alert("❌ حدث خطأ أثناء إنشاء المستخدم الفرعي");
    console.error(error);
  }
};
</script>

<style scoped>
::v-deep(.baseinput-core) {
  padding: 8px !important;
}
.create-subadmin-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.text-center {
  text-align: center;
}

.form-container {
  max-width: 500px;
  margin: auto;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.form-control, .form-select {
  font-size: 14px;
  padding: 10px;
  border-radius: 6px;
}

.btn-primary {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
}
</style>
