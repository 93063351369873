<template>
  <div class="apartment-list-container">
    <h2 class="text-center mb-4">قائمة الشقق</h2>
    <div class="btn-group mb-3" role="group">
      <button
          v-for="s in statusOptions"
          :key="s.value"
          class="btn"
          :class="selectedStatus === s.value ? 'btn-primary' : 'btn-outline-primary'"
          @click="changeStatusFilter(s.value)"
      >
        {{ s.label }}
      </button>
    </div>

    <div class="search-container mb-3">
      <input type="text" v-model="searchQuery" class="form-control" placeholder="ابحث عن الشقة" />
    </div>

    <table class="table table-striped">
      <thead>
      <tr>
        <th>رقم المنزل</th>
        <th>العنوان</th>
        <th>المنطقة</th>
        <th>الحافظة</th>
        <th>نوع العقار</th>
        <th>عدد الغرف</th>
        <th>السعر</th>
        <th>نوع العرض</th>
        <th>الصور</th>
        <th>الإجراءات</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="house in filteredHouses" :key="house.houseId">
        <td>{{ house.houseId }}</td>
        <td>{{ house.address }}</td>
        <td>{{ house.city.name }}</td>
        <td>{{ house.city.district.name }}</td>
        <td>{{ house.houseType.type }}</td>
        <td>{{ house.roomsNumber }}</td>
        <td>{{ house.price }}ل.س</td>
        <td>{{ house.listingType === 'RENT' ? 'إيجار' : 'بيع' }}</td>
        <td>
          <div class="image-container">
            <div class="image-scroll">
              <img
                  v-for="image in house.images"
                  :key="image.id"
                  :src="image.imageUrl"
                  class="house-image"
                  @click="openImage(image.imageUrl)"
              />
            </div>
            <span v-if="house.images.length === 0">لا توجد صور</span>
          </div>
        </td>
        <td>
          <button
              v-if="(house.status === 'PENDING' || house.status === 'INACTIVE') && user.user.userType === 'ROLE_CUSTOMER_ADMIN'"
              @click="updateHouseStatus(house.houseId, 'ACTIVE')"
              class="btn btn-success btn-sm"
          >
            ✔ موافقة
          </button>

          <button
              v-else-if="house.status === 'ACTIVE' && user.user.userType === 'ROLE_CUSTOMER_ADMIN'"
              @click="updateHouseStatus(house.houseId, 'INACTIVE')"
              class="btn btn-secondary btn-sm"
          >
            يقاف العرض
          </button>

          <button
            v-if="house.listingType === 'RENT'"
            class="btn btn-info btn-sm"
            @click="openBookingModal(house.houseId)"
          >
            📅 إحجز
          </button>

          <button v-if="user.user.userType==='ROLE_CUSTOMER_ADMIN'" class="btn btn-warning btn-sm" @click="openEditModal(house)">✏ تعديل</button>
          <button v-if="user.user.userType==='ROLE_CUSTOMER_ADMIN'" class="btn btn-danger btn-sm" @click="deleteHouse(house.houseId)">🗑 حذف</button>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- 📅 Booking Modal -->
    <div class="modal fade" id="bookingModal" tabindex="-1" aria-labelledby="bookingModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">تأكيد الحجز</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <VueDatePicker
                placeholder="اختر الفترة"
                :range="{ noDisabledRange: true }"
                :disabled-dates="disabledDates"
                v-model="date"
            />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">إلغاء</button>
            <button type="button" class="btn btn-primary" @click="submitBooking">تأكيد الحجز</button>
          </div>
        </div>
      </div>
    </div>

    <!-- ✏ Edit Modal -->
    <div class="modal fade" id="editModal" tabindex="-1">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <form @submit.prevent="submitUpdate">
            <div class="modal-header">
              <h5 class="modal-title">تعديل الشقة</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row g-3">
                <div class="col-md-6">
                  <label class="form-label">العنوان</label>
                  <input v-model="editableApartment.address" class="form-control" required />

                  <!-- <label class="form-label mt-2">رقم الهاتف</label>
                  <input type="number" v-model="editableApartment.phoneNumber" class="form-control" />

                  <label class="form-label mt-2">البريد الإلكتروني</label>
                  <input v-model="editableApartment.email" class="form-control" /> -->

                  <label class="form-label mt-2">عدد الغرف</label>
                  <input v-model="editableApartment.roomsNumber" type="text" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="editableApartment.roomsNumber = editableApartment.roomsNumber.replace(/[^\d\u0660-\u0669]/g, '')"  />

                  <label  class="form-label mt-2">عدد الحمامات</label>
                  <input v-model="editableApartment.bathroomsNumber"  type="text" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="editableApartment.bathroomsNumber = editableApartment.bathroomsNumber.replace(/[^\d\u0660-\u0669]/g, '')"  />
<!-- 
                  <label class="form-label mt-2">الطابق</label>
                  <input v-model="editableApartment.floor" type="text" class="form-control" placeholder="أدخل رقم الطابق" /> -->
                  <div class="mb-3">
                  <label class="form-label mt-2">الطابق</label>
                  <select v-model="editableApartment.floor" class="tabek form-label mt-2">
                    <option value="-1">الطابق</option>
                    <option value="0">طابق أرضي</option>
                    <option v-for="n in 13" :key="n" :value="n">{{ n }}</option>
                  </select>
                  </div>
                  <label class="form-label mt-2">نوع العقار</label>
                  <select v-model="editableApartment.houseTypeId" class="form-select">
                    <option v-for="type in houseTypes" :value="type.id" :key="type.id">{{ type.type }}</option>
                  </select>

                  <!-- <div class="form-check mt-2">
                    <input v-model="editableApartment.furnished" type="checkbox" class="form-check-input" id="editFurnished" />
                    <label class="form-check-label" for="editFurnished">مفروشة</label>
                  </div> -->
                  <div class="form-check mt-2">
                    <label for="furnished" class="form-label">مواصفات</label>
                    <select v-model="editableApartment.furnished" class="form-select" id="furnished">
                      <option :value="null">لا شيء</option>
                      <option :value="true">مفروشة</option>
                      <option :value="false">غير مفروشة</option>
                    </select>
                  </div>

                </div>

                <div class="col-md-6">
                  <label class="form-label">نوع العرض</label>
                  <select v-model="editableApartment.listingType" class="form-select">
                    <option value="RENT">إيجار</option>
                    <option value="BUY">بيع</option>
                  </select>

                  <label class="form-label mt-2">المساحة</label>
                  <input v-model="editableApartment.area" type="text" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="editableApartment.area = editableApartment.area.replace(/[^\d\u0660-\u0669]/g, '')"  required />

                  <label class="form-label mt-2">المحافظة</label>
                  <select v-model="editableApartment.districtId" class="form-select" @change="fetchCities">
                    <option value="">اختر المحافظة</option>
                    <option v-for="d in districts" :value="d.id" :key="d.id">{{ d.name }}</option>
                  </select>

                  <label class="form-label mt-2">المنطقة</label>
                  <select v-model="editableApartment.cityId" class="form-select">
                    <option value="">اختر المنطقة</option>
                    <option v-for="c in cities" :value="c.id" :key="c.id">{{ c.name }}</option>
                  </select>

                  <label class="form-label mt-2">رابط الخريطة</label>
                  <input v-model="editableApartment.mapsLink" class="form-control" />
                  <label class="form-label mt-2">رابط خارجي (مثال: يوتيوب أو فايسبوك)</label>
                  <input
                      class="form-control"
                      v-model="editableApartment.otherLinks"
                      placeholder="أدخل رابط خارجي"
                  />
                </div>
              </div>

              <label class="form-label mt-3">الوصف</label>
              <textarea v-model="editableApartment.description" class="form-control" rows="3"></textarea>

              <hr class="my-4" />

              <div class="mb-3">
                <label class="form-label">📸 صور الشقة</label>
                <div class="d-flex flex-wrap gap-3">
                  <div
                      v-for="image in editableApartment.images"
                      :key="image.id"
                      class="position-relative shadow-sm border rounded"
                  >
                    <img :src="image.imageUrl" class="edit-preview-image" />
                    <button
                        type="button"
                        class="btn btn-sm btn-danger position-absolute top-0 end-0"
                        @click="deleteImage(image.id)"
                        title="حذف"
                    >×</button>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <label class="form-label">➕ أضف صور جديدة</label>
                <input type="file" multiple @change="handleImageUpload" class="form-control" accept="image/*" />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">إلغاء</button>
              <button type="submit" class="btn btn-primary">💾 حفظ</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue';
import apiClient from "@/services/apiClient";
import { Modal } from 'bootstrap';
import { eachDayOfInterval, parseISO } from 'date-fns';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { useUser } from "@/composables/useUser";

const searchQuery = ref('');
const houses = ref([]);
const companyId = localStorage.getItem("companyId") || 1;

const booking = ref({ houseId: null });
const date = ref([]);
const disabledDates = ref([]);

const editableApartment = ref({});
const houseTypes = ref([]);
const districts = ref([]);
const cities = ref([]);
const selectedStatus = ref('PENDING');

const user = useUser()
const generateDisabledDates = (bookings) => {
  const disabled = [];
  bookings.forEach(booking => {
    const from = parseISO(booking.dateFrom);
    const to = parseISO(booking.dateTo);
    const days = eachDayOfInterval({ start: from, end: to });
    disabled.push(...days);
  });
  return disabled;
};

const openBookingModal = async (houseId) => {
  booking.value.houseId = houseId;
  date.value = [];
  try {
    const response = await apiClient.get(`/house/${houseId}`);
    disabledDates.value = generateDisabledDates(response.data.bookinsg || []);
  } catch (error) {
    console.error("Error fetching bookings:", error);
  }
  const modal = new Modal(document.getElementById('bookingModal'));
  modal.show();
};

const openEditModal = (house) => {
  editableApartment.value = {
    id: house.houseId,
    houseTypeId: house.houseType.id,
    price: house.price,
    roomsNumber: house.roomsNumber,
    bathroomsNumber: house.bathroomsNumber,
    listingType: house.listingType,
    area: house.area,
    cityId: house.city.id,
    districtId: house.city.district.id,
    description: house.description,
    address: house.address,
    // phoneNumber: house.companyPhoneNumber || '',
    // email: house.companyEmail || '',
    mapsLink: house.mapsLink,
    images: house.images || [],
    floor: house.floor,
    furnished: house.furnished,
    otherLinks:house.otherLinks || ''
  };
  fetchCities();
  new Modal(document.getElementById('editModal')).show();
};

const submitUpdate = async () => {
  try {
    // Filter out keys with empty string, null, or undefined
    const filteredData = Object.fromEntries(
        Object.entries(editableApartment.value).filter(
            ([, value]) => value !== "" && value !== null && value !== undefined
        )
    );

    await apiClient.post(`/house/update`, filteredData);
    alert('✅ تم التحديث بنجاح');
    const modal = Modal.getInstance(document.getElementById('editModal'));
    modal.hide();
    fetchHouses();
  } catch (error) {
    alert('❌ فشل التحديث');
    console.error(error);
  }
};


const handleImageUpload = async (e) => {
  const files = e.target.files;
  if (!files.length) return;
  try {
    for (const file of files) {
      const formData = new FormData();
      formData.append("houseId", editableApartment.value.id);
      formData.append("imageUrl", file);
      await apiClient.post("/house/image/create", formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    }
    alert("✅ تم رفع الصور");
    fetchHouses();
  } catch (error) {
    alert("❌ فشل رفع الصور");
    console.error(error);
  }
};

const deleteImage = async (imageId) => {
  if (!confirm("هل تريد حذف هذه الصورة؟")) return;
  try {
    await apiClient.get(`/house/image/delete/${imageId}`);
    editableApartment.value.images = editableApartment.value.images.filter(img => img.id !== imageId);
  } catch (error) {
    alert("❌ فشل حذف الصورة");
    console.error(error);
  }
};

const deleteHouse = async (houseId) => {
  if (!confirm("هل أنت متأكد أنك تريد حذف هذا العقار؟")) return;
  try {
    await apiClient.post(`/house/delete/${houseId}`);
    alert("✅ تم حذف العقار بنجاح");
    houses.value = houses.value.filter(h => h.houseId !== houseId);
  } catch (error) {
    alert("❌ فشل الحذف");
    console.error(error);
  }
};

const submitBooking = async () => {
  if (!date.value[0] || !date.value[1]) {
    alert('❗ يرجى اختيار فترة صالحة');
    return;
  }
  try {
    await apiClient.post('/house/bookings', {
      houseId: booking.value.houseId,
      status: "APPROVED",
      startDate: date.value[0].toISOString().split('T')[0],
      endDate: date.value[1].toISOString().split('T')[0],
    });
    alert('✅ تم الحجز بنجاح');
    const modal = Modal.getInstance(document.getElementById('bookingModal'));
    if (modal) modal.hide();
  } catch (error) {
    alert('❌ فشل الحجز');
    console.error(error);
  }
};

const fetchHouses = async () => {
  try {
    const response = await apiClient.get(`/houses/${companyId}/${selectedStatus.value}`);
    houses.value = response.data?.houses ?? [];
  } catch (error) {
    console.error("Error fetching houses:", error.response?.data || error.message);
    houses.value = [];
  }
};

const fetchHouseTypes = async () => {
  try {
    const response = await apiClient.get("/houseTypes");
    houseTypes.value = response.data;
  } catch (error) {
    console.error("Error fetching house types:", error.response?.data || error.message);
  }
};

const fetchDistricts = async () => {
  try {
    const response = await apiClient.get("/districts");
    districts.value = response.data;
  } catch (error) {
    console.error("Error fetching districts:", error.response?.data || error.message);
  }
};

const fetchCities = async () => {
  if (!editableApartment.value.districtId) return;
  try {
    const response = await apiClient.get(`/districts/${editableApartment.value.districtId}/cities`);
    cities.value = response.data;
  } catch (error) {
    console.error("Error fetching cities:", error.response?.data || error.message);
  }
};

const filteredHouses = computed(() => {
  return houses.value.filter(house =>
      house.address.includes(searchQuery.value) ||
      house.city.name.includes(searchQuery.value) ||
      house.city.district.name.includes(searchQuery.value)
  );
});

const openImage = (imageUrl) => {
  window.open(imageUrl, "_blank");
};

const updateHouseStatus = async (houseId, status) => {
  try {
    const payload = { houseId, status };
    await apiClient.post("/house/changeStatus", payload);
    alert(`تم تحديث حالة العقار إلى ${status === 'ACTIVE' ? 'مُفعل' : 'غير مفعل'}`);
    houses.value = houses.value.map(h => h.houseId === houseId ? { ...h, status } : h);
    window.location.reload();
  } catch (error) {
    console.error("Error updating status:", error.response?.data || error.message);
    alert("حدث خطأ أثناء تحديث الحالة");
  }
};


const statusOptions = [
  { label: '📥 قيد الانتظار', value: 'PENDING' },
  { label: '✅ مفعل', value: 'ACTIVE' },
  { label: '🚫 غير مفعل', value: 'INACTIVE' },
];
const changeStatusFilter = (status) => {
  selectedStatus.value = status;
  fetchHouses();
};
onMounted(() => {
  fetchHouses();
  fetchHouseTypes();
  fetchDistricts();
});

watch(() => editableApartment.value.districtId, () => {
  fetchCities();
});

</script>
<style scoped>
.tabek {
  padding: 10px;
  border-radius: 12px;
  border: none;
  background-color: rgba(255,255,255,0.6);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}
.apartment-list-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-container input {
  max-width: 300px;
  margin-bottom: 20px;
}

.image-container {
  max-width: 200px;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 6px;
}

.image-scroll {
  display: flex;
  gap: 5px;
}

.house-image {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
  cursor: pointer;
  transition: transform 0.2s;
}

.house-image:hover {
  transform: scale(1.1);
}

.image-container::-webkit-scrollbar {
  height: 6px;
}

.image-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
}

.image-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.image-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.edit-preview-image {
  width: 120px;
  height: 90px;
  object-fit: cover;
  border-radius: 6px;
}
button {
  margin: 5px;
}
</style>
<style>
.dp__cell_disabled{
  background: red !important;
  opacity: 85%;
}
.dp__range_end{
  opacity: 45% !important ;
}
.dp__range_start{
  opacity: 45% !important ;
}
.dp__range_between{
  opacity: 45% !important ;
}
.dp__cell_inner{
  background: green;
  opacity: 85%;
}

</style>
