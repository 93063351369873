import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import HouseDetails from '../views/HouseDetails.vue';

// Admin User Layout & Views
import UserAdminLayout from '@/layouts/UserAdminLayout.vue';
import CreateApartment from '@/views/UserAdmin/CreateAppartment.vue';
import RequestApartment from '@/views/UserAdmin/RequestedAppartment.vue';
import CreateSubAdmin from '@/views/UserAdmin/CreateSubAdmin.vue';
import ViewAppartments from '@/views/UserAdmin/ViewAppartments.vue';

// Admin Panel Layout & Views
import AdminLayout from "@/layouts/AdminLayout.vue";
import AdminUserList from "@/views/Admin/DashboardVue.vue";
import CreateAdminUser from "@/views/Admin/CreateAdminUser.vue";
import PendingApartments from "@/views/Admin/PendingAppartmentsVue.vue";
// import BookingCalendarVue from "@/views/UserAdmin/BookingCalendarVue.vue";
import UserProfile from "@/views/User/UserProfile.vue";
import BookingView from "@/views/User/BookingView.vue";
import SearchView from "@/views/SearchView.vue";
import UsersView from "@/views/Admin/UsersView.vue";
import AdminUsersView from "@/views/UserAdmin/AdminUsersView.vue";
import CompanyView from "@/views/Admin/CompanyView.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsAndCondition from "@/views/TermsAndCondition.vue";
import ContactForm from "@/views/ContactForm.vue";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { requiresAuth: false },
  },

  {
    path: '/search',
    name: 'Search',
    component: SearchView,
    meta: { requiresAuth: false },
  },
  {
    path: '/house/:id',
    name: 'HouseDetails',
    component: HouseDetails,
    meta: { requiresAuth: false },
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy,
    meta: { requiresAuth: false },
  },
  {
    path: '/terms-and-condition',
    name: 'termsAndCondition',
    component: TermsAndCondition,
    meta: { requiresAuth: false },
  },
  {
    path: '/contact-form',
    name: 'contactForm',
    component: ContactForm,
    meta: { requiresAuth: false },
  },


  //user routes
  {
    path: '/user',
    meta: { requiresAuth: true, requiredUserType: 'ROLE_USER' },
    children: [
      {
        path: 'view-profile',
        name: 'ViewProfile',
        component: UserProfile,
      },
      {
        path: 'view-bookings',
        name: 'BookingView',
        component: BookingView,
      },
    ],
  },

  // Admin User Routes
  {
    path: '/admin-user',
    component: UserAdminLayout,
    meta: {
      requiresAuth: true,
      requiredUserTypes: ['ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER_EMPLOYEE']
    },
    children: [
      {
        path: 'view-apartments',
        name: 'UserAdminViewApartments',
        component: ViewAppartments,
      },
      {
        path: 'users',
        name: 'AdminUsersView',
        component: AdminUsersView,
      },
      {
        path: 'create-apartment',
        name: 'UserAdminCreateApartment',
        component: CreateApartment,
      },
      {
        path: 'request-apartment',
        name: 'UserAdminRequestApartment',
        component: RequestApartment,
      },
      {
        path: 'create-sub-admin',
        name: 'UserAdminCreateSubAdmin',
        component: CreateSubAdmin,
      },
      // {
      //   path: 'calendar',
      //   name: 'Calendar',
      //   component: BookingCalendarVue,
      // },
    ],
  },

  // Admin Panel Routes
  {
    path: '/admin',
    component: AdminLayout,
    meta: { requiresAuth: true, requiredUserType: 'ROLE_MODERATOR' },
    children: [
      {
        path: 'list-admin-users',
        name: 'AdminUserList',
        component: AdminUserList,
      },
      {
        path: 'admin-users',
        name: 'AdminUsers',
        component: UsersView,
      },
      {
        path: 'create-admin-user',
        name: 'CreateAdminUser',
        component: CreateAdminUser,
      },
      {
        path: 'companies',
        name: 'Company',
        component: CompanyView,
      },
      {
        path: 'pending-apartments',
        name: 'PendingApartments',
        component: PendingApartments,
      },
      {
        path: '/company/:companyId/houses',
        name: 'CompanyHouses',
        component: () => import('@/views/UserAdmin/CompanyHouses.vue')
      }
      
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Navigation Guard
router.beforeEach((to, from, next) => {
  const { useUser } = require('@/composables/useUser');
  const { user } = useUser();

  const isAuthenticated = user.isAuthenticated;
  const userRole = user.userType;

  if (to.meta.requiresAuth) {
    if (!isAuthenticated) {
      return next({ name: 'Home' }); // Redirect if not logged in
    }

    const requiredRoles = to.meta.requiredUserTypes || [to.meta.requiredUserType];
    if (requiredRoles && !requiredRoles.includes(userRole)) {
      return next({ name: 'Home' }); // Redirect if role is not allowed
    }
  }

  next();
});

export default router;
