<template>
  <div class="luxury-home">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container">
        <a class="navbar-brand logo" href="/">
          <img class="image-logo" src="../assets/White-Logo-512-x512.png" alt="Logo" />
        </a>

        <!-- 🔽 Navbar Toggler for Mobile -->
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- 🔁 Collapsible Content with ID -->
        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
          <ul class="navbar-nav align-items-center">
            <li class="nav-item" v-if="!user.isAuthenticated">
              <button class="btn login-btn" @click="openModal">تسجيل الدخول</button>
            </li>
            <li v-else class="d-flex align-items-center gap-2">
              <i class="bi bi-person-circle text-white fs-4 me-1" @click="openProfileModal" style="cursor: pointer;"></i>
              <span class="text-white" @click="openProfileModal" style="cursor: pointer;">ملف الشخصي</span>
              <button class="btn logout-btn" @click="logout">الخروج</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Sign In Modal -->
    <div class="modal fade" id="signInModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ isLogin ? 'تسجيل الدخول' : 'إنشاء حساب' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs mb-3">
              <li class="nav-item">
                <button class="nav-link" :class="{ active: isLogin }" @click="isLogin = true">تسجيل الدخول</button>
              </li>
              <li class="nav-item">
                <button class="nav-link" :class="{ active: !isLogin }" @click="isLogin = false">التسجيل</button>
              </li>
            </ul>

            <!-- Login Form -->
            <form v-if="isLogin" @submit.prevent="signIn">
              <div class="mb-3">
                <label class="form-label">اسم المستخدم</label>
                <input v-model="username" type="text" class="form-control" required />
              </div>
              <div class="mb-3 position-relative">
                <label class="form-label">كلمة المرور</label>
                <input :type="showPassword ? 'text' : 'password'" v-model="password" class="form-control" required />
                <span class="eye-icon position-absolute" @click="togglePassword">👁️</span>
              </div>
              <button type="submit" class="btn btn-primary submit-button w-100">دخول</button>
            </form>

            <!-- Registration Form -->
            <div v-else>
              <ul class="nav nav-pills mb-3">
                <li class="nav-item">
                  <button class="nav-link" :class="{ active: userType === 'ROLE_USER' }" @click="userType = 'ROLE_USER'; registerData.role = userType">مستخدم عادي</button>
                </li>
                <li class="nav-item">
                  <button
                      class="nav-link"
                      :class="{ active: userType === 'ROLE_CUSTOMER_ADMIN' }"
                      @click="() => { userType = 'ROLE_CUSTOMER_ADMIN'; registerData.role = userType; }"
                  >
                    وكيل عقاري
                  </button>
                </li>
              </ul>

              <form @submit.prevent="register">
                <div class="mb-3">
                  <label class="form-label">اسم المستخدم</label>
                  <input v-model="registerData.username" type="text" class="form-control" required />
                </div>
                <div class="mb-3">
                  <label class="form-label">الاسم الكامل</label>
                  <input v-model="registerData.fullName" type="text" class="form-control" required />
                </div>
                <div class="mb-3">
                  <label class="form-label">البريد الإلكتروني</label>
                  <input v-model="registerData.email" type="email" class="form-control" required />
                </div>
                <div class="mb-3">
                  <label class="form-label">رقم الهاتف الشخصي</label>
<!--                  <vue-tel-input v-model="registerData.phoneNumber" mode="international"></vue-tel-input>-->
                  <phone-input
                      @phoneData="handlePhoneChange"
                      v-model="registerData.phoneNumber"
                      :value="Number(registerData.phoneNumber)"
                      :allowed=countries
                      :default-country="'SY'"
                  />
                </div>
                <div class="mb-3 position-relative">
                  <label class="form-label">كلمة المرور</label>
                  <input :type="showPasswordRegister ? 'text' : 'password'" v-model="registerData.password" class="form-control" required />
                  <span class="eye-icon position-absolute" @click="togglePasswordRegisterPassword">👁️</span>                </div>

                <!-- Admin-Specific Fields -->
                <div v-if="userType === 'ROLE_CUSTOMER_ADMIN'">
                  <div class="mb-3">
                    <label class="form-label">اسم المكتب العقاري</label>
                    <input v-model="registerData.companyName" type="text" class="form-control" required />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">عنوان الشركة</label>
                    <input v-model="registerData.companyAddress" type="text" class="form-control" required />
                  </div>
                  <div class="mb-3">
                    <label class="form-label">رقم هاتف الشركة</label>
                    <phone-input
                        :value="Number(registerData.companyPhoneNumber)"
                        @phoneData="(e)=>registerData.companyPhoneNumber = e.number"
                        :allowed=countries
                        :default-country="'SY'"
                    />                  </div>
                  <div class="mb-3">
                    <label class="form-label">وصف الشركة</label>
                    <textarea v-model="registerData.companyDescription" class="form-control" required></textarea>
                  </div>
                  <div class="mb-3">
                    <label class="form-label">
                      المستندات الرسمية (صورة عن الهوية، صورة عن السجل التجاري)
                    </label>

                    <input type="file" class="form-control" @change="uploadDocuments" multiple />
                  </div>
                </div>

                <button type="submit" class="btn btn-success submit-button w-100">تسجيل</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Profile Modal -->
    <div class="modal fade" id="profileModal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">👤 الملف الشخصي</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
          </div>
          <div class="modal-body">
            <!-- Tabs -->
            <ul class="nav nav-tabs mb-3">
              <li class="nav-item">
                <button class="nav-link" :class="{ active: profileTab === 'info' }" @click="profileTab = 'info'">معلومات المستخدم</button>
              </li>
              <li class="nav-item">
                <button class="nav-link" :class="{ active: profileTab === 'password' }" @click="profileTab = 'password'">تغيير كلمة المرور</button>
              </li>
            </ul>

            <!-- User Info Form -->
            <form v-if="profileTab === 'info'" @submit.prevent="updateProfile">
              <div class="mb-3">
                <label class="form-label">الاسم الكامل</label>
                <input v-model="profileData.fullName" type="text" class="form-control" required />
              </div>
              <div class="mb-3">
                <label class="form-label">البريد الإلكتروني</label>
                <input v-model="profileData.email" type="email" class="form-control" required />
              </div>
              <div class="mb-3">
                <label class="form-label">رقم الهاتف</label>
                <phone-input
                    @phoneData="(e)=>profileData.phoneNumber = e.number"
                    :value="Number(profileData.phoneNumber)"
                    :allowed=countries
                  />

              </div>
              <button type="submit" class="btn  submit-button w-100">💾 تحديث</button>
            </form>

            <!-- Password Change Form -->
            <form v-else @submit.prevent="changePassword">
              <div class="modal-body position-relative">
                <label class="form-label">كلمة المرور الجديدة</label>
                <input :type="showResetPassword ? 'text' : 'password'" class="form-control" v-model="passwordData.password" placeholder="ادخل كلمة مرور جديدة" />
                <span class="eye-icon position-absolute me-3" @click="toggleShowResetPassword">👁️</span>
              </div>
              <button type="submit" class="btn submit-button w-100">🔒 تغيير</button>
            </form>
          </div>
        </div>
      </div>
    </div>


    <!-- Page Content -->
    <div class="main-content">
      <router-view></router-view>
    </div>
  </div>

  <!-- Slot for custom content -->
  <div class="main-content">
    <slot></slot>
  </div>

  <footer class="aqar-footer">
    <div class="container py-4">
      <div class="row text-end text-md-start">
        <!-- نشر إعلان -->
        <div class="col-md-4 mb-4  text-end">
          <h5 class="footer-title">نشر اعلان</h5>
          <div @click="isLogin = false;userType = 'ROLE_CUSTOMER_ADMIN';openModal()" class="btn publish-btn">نشر اعلان</div>
          <p class="mt-3">تابعونا على مواقع التواصل الاجتماعي</p>
          <div class="social-icons">
            <a href="#" aria-label="Instagram"><i class="bi bi-instagram"></i></a>
            <a href="#" aria-label="Facebook"><i class="bi bi-facebook"></i></a>
          </div>
        </div>

        <!-- روابط التصفح -->
        <div class="col-md-4 mb-4 text-end">
          <h5 class="footer-title">تصفح في الموقع</h5>
          <ul class="footer-links">
            <li><a href="/terms-and-condition">الشروط والأحكام</a></li>
            <li><a href="/privacy-policy">سياسة الخصوصية</a></li>
            <li><a href="/contact-form">تواصل معنا</a></li>
          </ul>
        </div>

        <!-- عن المنصة -->
        <div class="col-md-4 mb-4 text-end">
          <h5 class="footer-title">سوريا 14</h5>
          <p class="footer-desc">
            منصة سوريا 14 للإعلانات العقارية من أكبر مواقع نشر الإعلانات العقارية في سوريا. جميع خدمات العقارات في مكان واحد مع إمكانية تحديد الموقع على الخريطة.
          </p>
        </div>
      </div>

      <hr class="text-muted text-white bg-white "  />
      <div class="text-end text-white text-center small">
        جميع الحقوق محفوظة &copy; سوريا 14 - 2025
      </div>
    </div>
  </footer>
</template>

<script setup>
import { Modal } from "bootstrap";
// import VueTelInput from 'vue-tel-input';
// import 'vue-tel-input/vue-tel-input.css';

import { PhoneInput } from '@lbgm/phone-number-input';
import '@lbgm/phone-number-input/style';
import { useUser } from "@/composables/useUser";
import { useRouter } from "vue-router";
import { ref } from "vue";
import apiClient from "@/services/apiClient";
import {countries} from "@/iso";

const { user } = useUser();
const { setUser } = useUser();
const router = useRouter();

const isLogin = ref(true);
const userType = ref('ROLE_CUSTOMER_ADMIN');
const username = ref("");
const password = ref("");
const showPassword = ref(false);
const showResetPassword = ref(false)
const showPasswordRegister = ref(false);
const registerData = ref({
  username: "",
  fullName:"",
  email: "",
  role: userType.value,
  password: "",
  companyName: "",
  companyAddress: "",
  companyPhoneNumber: "",
  phoneNumber: "",
  companyDescription: "",
  companyStatus: "PENDING",
  documents: [],
});

const userInfo = JSON.parse(localStorage.getItem('userInfo'));

const profileTab = ref("info");

const profileData = ref({
  fullName:  userInfo?.fullName,
  email: userInfo?.email,
  phoneNumber: userInfo?.phoneNumber,
  id: userInfo?.id,
  username: userInfo?.username
});
console.log(userInfo,'userInfo')
const passwordData = ref({
  password: '',
  userId: userInfo?.id
});


const openProfileModal = () => {
  const modal = new Modal(document.getElementById('profileModal'));
  modal.show();
};

const updateProfile = async () => {
  try {
    await apiClient.post('/users/update', profileData.value); // Adjust API route as needed
    //update user info in local storage
    localStorage.setItem('userInfo',JSON.stringify(profileData.value))
    alert('✅ تم تحديث المعلومات بنجاح');
  } catch (error) {
    alert(error.response?.data?.message || 'حدث خطأ أثناء التحديث');
  }
};

const handlePhoneChange = (e) => {
  registerData.value.phoneNumber = e.number
}
const changePassword = async () => {
  try {
    await apiClient.post('/users/reset', passwordData.value); // Adjust API route
    alert('✅ تم تغيير كلمة المرور');
    passwordData.value.current = '';
    passwordData.value.new = '';
  } catch (error) {
    alert(error.response?.data?.message || 'خطأ في تغيير كلمة المرور');
  }
};

const uploadDocuments = (event) => {
  const selectedFiles = Array.from(event.target.files);

  if (selectedFiles.length > 2) {
    alert('يمكنك تحميل مستندين كحد أقصى فقط.');
    event.target.value = '';
    registerData.value.documents = [];
    return;
  }

  registerData.value.documents = selectedFiles;
};


const logout = () => {
  localStorage.clear();
  location.reload();
};

const togglePassword = () => {
  showPassword.value = !showPassword.value;
};

const toggleShowResetPassword = () => {
  showResetPassword.value = !showResetPassword.value;
};

const togglePasswordRegisterPassword = () => {
  showPasswordRegister.value = !showPasswordRegister.value;
};

const openModal = () => {
  const modalElement = document.getElementById("signInModal");
  if (modalElement) {
    const myModal = new Modal(modalElement);
    myModal.show();
  }
};

const signIn = async () => {
  try {
    const response = await apiClient.post("/api/auth/signin", {
      username: username.value,
      password: password.value,
    });

    if (!response.data || !response.data.token) {
      throw new Error(response.data?.message || "Invalid response from server");
    }

    localStorage.setItem("companyName", response.data.companyName || "");
    localStorage.setItem("token", response.data.token);
    localStorage.setItem("role", response.data.role || "USER");
    localStorage.setItem("companyId", response.data.companyId || "");
    localStorage.setItem('userInfo',JSON.stringify(response.data))
    setUser(response.data);

    const modalElement = document.getElementById("signInModal");
    if (modalElement) {
      const myModal = Modal.getInstance(modalElement) || new Modal(modalElement);
      myModal.hide();
    }

    if (response.data.role === "ROLE_MODERATOR") {
      router.push('/admin/list-admin-users/');
    } else if (response.data.role === "ROLE_CUSTOMER_ADMIN" || response.data.role === "ROLE_CUSTOMER_EMPLOYEE") {
      router.push('/admin-user/view-apartments');
    } else {
      window.location.reload(); // Reload to reflect logout
      router.push('/');
    }

  } catch (error) {
    console.error("❌ Login Error:", error.response?.data || error.message);
    alert(error.response?.data.message);
  }
};

const register = async () => {
  try {
    const formData = new FormData();
    console.log(registerData,'registerds')
    Object.keys(registerData.value).forEach((key) => {
      if (key === "documents") return;
      formData.append(key, registerData.value[key]);
    });

    registerData.value.documents.forEach((file, index) => {
      formData.append(`companyImages[${index}]`, file);
    });

    await apiClient.post("/api/auth/signup", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });

    alert( registerData.value.role==='ROLE_CUSTOMER_ADMIN' ? "تم ارسال الطلب الى ادارة الموقع, سيتم التواصل معكم فور الموافقة" : 'تَمَّ إنشاء الحساب بنجاح');
    isLogin.value = true;
  } catch (error) {
    console.error("Registration Error:", error.response?.data || error.message);
    alert(error.response?.data.message);
  }
};
</script>

<style scoped>
.luxury-home{
}
.navbar {
  background: #3B412C;
  backdrop-filter: blur(10px);
  padding: 12px 20px;
  position: fixed;
  width: 100%;
  z-index: 10;
}

.logo {
  font-weight: bold;
}

.active {
  background: #B38F4B !important;
  color: white !important;
}

.nav-link {
  color: black !important;
}

.submit-button {
  background: #B38F4B;
}

.image-logo {
  width: 70px;
}

.eye-icon {
  right: 13px;
  top: 56%;
  cursor: pointer;
}

.login-btn,
.logout-btn {
  background-color: #B38F4B;
  color: white;
  border-radius: 12px;
}
.login-btn:hover{
  color: white !important;
  transform: translateY(-2px);
}

::v-deep(.baseinput-core) {
  padding: 8px !important;
}
.lang-btn {
  color: white;
}

.aqar-footer {
  text-align: center;
  background-color: #3B412C;
  border-top: 1px solid #ccc;
  color: white;
  font-family: 'Cairo', sans-serif;
}

.footer-title {
  width: 65%;
  text-align: end !important;
  font-weight: bold;
  border-bottom: 2px solid #B38F4B;
  padding-bottom: 6px;
  display: inline-block;
  color: white;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin: 6px 0;
}

.footer-links a {
  color: white;
  text-decoration: none;
}

.footer-links a:hover {
  color: #B38F4B;
}

.footer-desc {
  font-size: 14px;
  line-height: 1.6;
  color: white;
}

.social-icons a {
  font-size: 22px;
  color: white;
  margin: 0 10px;
  transition: 0.3s ease;
}

.social-icons a:hover {
  color: #00b9ae;
}

.publish-btn {
  display: block;
  width: 65%;
  margin-left: auto;
  background-color: #B38F4B;
  color: white;
  border-radius: 24px;
  padding: 5px 30px;
  font-weight: bold;
  border: none;
}
.publish-btn:hover{
  transform: translateY(-2px);
}

</style>
