<template>
  <div class="house-card my-4 card shadow-lg mw-50">
    <div class="row g-0 p-3 align-items-center">
      <!-- 🔥 Image Section -->
      <div class="col-md-5 d-flex align-items-center">
        <div :id="'carousel-' + house.houseId" class="carousel slide w-100 rounded" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div v-for="(img, index) in house.images" :key="index" :class="['carousel-item', { active: index === 0 }]">
              <img :src="img" class="d-block w-100 house-img" alt="House Image">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" :data-bs-target="'#carousel-' + house.houseId" data-bs-slide="prev">
            <span class="carousel-control-prev-icon"></span>
          </button>
          <button class="carousel-control-next" type="button" :data-bs-target="'#carousel-' + house.houseId" data-bs-slide="next">
            <span class="carousel-control-next-icon"></span>
          </button>
        </div>
<!--        <div v-else class="no-image-placeholder">🚫 لا توجد صور</div>-->
      </div>

      <!-- 🏠 Property Details -->
      <div class="col-md-7 d-flex flex-column justify-content-between p-4">
        <div class="d-flex justify-content-between align-items-center">
          <span class="badge bg-primary px-3 py-2">🏢 {{ house.propertyType || 'نوع غير معروف' }}</span>
          <h5 class="price-text fw-bold">
            💰 {{ house.price || 'غير متوفر' }} ل.س
            <span v-if="house.listingType === 'RENT'"> شهرياً</span>
          </h5>
        </div>

        <h6 class="card-title fw-bold mt-2">🏡 {{ house.title || 'عنوان غير متوفر' }}</h6>
        <p class="property-details text-muted">
          📍 {{ house.city || 'مدينة غير معروفة' }} | {{ house.district || 'حي غير معروف' }}
        </p>
        <p
            v-if="house.roomsNumber != null || house.bathroomsNumber != null || house.area != null"
            class="property-info text-muted"
        >
          <span v-if="house.roomsNumber != null">غرف 🛏 {{ house.roomsNumber }}</span>
          <span v-if="house.roomsNumber != null && (house.bathroomsNumber != null || house.area != null)"> | </span>

          <span v-if="house.bathroomsNumber != null">🚿 {{ house.bathroomsNumber }} حمامات</span>
          <span v-if="house.bathroomsNumber != null && house.area != null"> | </span>

          <span v-if="house.area != null">📏 {{ house.area }} م²</span>
        </p>


        <p v-if="house.floor != null || house.furnished != null" class="property-info text-muted">
          <span v-if="house.floor !== null">
              الطابق: {{ house.floor === 0 ? 'الأرضي' : house.floor }}
            </span>


          <span v-if="house.floor != null && house.furnished != null"> | </span>
          <span v-if="house.furnished != null">
            مواصفات: {{ house.furnished ? 'مفروش' : 'غير مفروش' }}
    </span>
        </p>

        <p v-if="house.otherLinks !==null" class="text-muted small">
          🔗 <a
            :href="formatLink(house.otherLinks)"
            target="_blank"
            rel="noopener noreferrer"
        >
          رابط إضافي
        </a>
        </p>

        <p class="text-secondary small">📝 {{ house.description || 'لا يوجد وصف' }}</p>
        <p class="text-muted small">
          🏢 الشركة: {{ house.companyName || 'غير متوفر' }} |
          📞 {{ house.companyPhoneNumber || 'غير متوفر' }}
        </p>

        <!-- 🔍 View Details Button -->
        <button class="btn btn-primary check-button btn-lg mt-3 w-100" @click="handleClick">
          🔍 عرض التفاصيل
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["house"],
  methods: {
    handleClick(event) {
      if (!event.target.closest(".carousel")) {
        this.$emit("viewHouse", this.house.houseId);
      }
    },
    formatLink(link) {
      if (!link) return '#';
      return link.startsWith('http://') || link.startsWith('https://')
          ? link
          : `https://${link}`;
    }
  }
};
</script>

<style scoped>
/* 🔥 Card Styling */
.house-card {
  border: 1px solid #B38F4B ;
  background: rgba(255, 255, 255, 0.4);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.house-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
}

p{
  color: black !important;
}
/* 🔥 Image Section */
.house-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
}

.badge{
  background: #B38F4B !important;

}
.check-button{
  background: #B38F4B !important;
}
.check-button:hover{
  transform: translateY(-2px);
}
.price-text{
  color: #3B412C !important;
}

.no-image-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  background: #f8f9fa;
  font-size: 1.2rem;
  color: #6c757d;
  border-radius: 10px;
}

/* 🔥 Property Info */
.property-info, .property-details {
  font-size: 1rem;
  margin-top: 5px;
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

/* 🔥 Button Styling */
.btn-primary {
  background: #007bff;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  transition: 0.3s;
}

.btn-primary:hover {
  background: #0056b3;
}
</style>