<template>
  <div class="homepage">
    <UserLayout>
      <!-- Search Bar Component -->
      <div class="search-section">
        <!-- Template -->
        <SearchBar @search="onSearch" />
      </div>

      <!-- House Listings -->
      <div class="container mt-5 house-cards">
        <div class="row">
          <div
            class="col-lg-12 col-md-6"
            v-for="house in houses"
            :key="house.houseId"
          >
            <HouseCard :house="house" @viewHouse="viewHouse" />
          </div>
        </div>

        <!-- No Results Message (only after search and not loading) -->
        <div v-if="hasSearched && !loading && houses.length === 0" class="text-center text-white no-results">
          <h4>عذرًا، لم يتم العثور على نتائج مطابقة لبحثك</h4>
          <p>حاول تعديل معايير البحث للحصول على نتائج أفضل</p>
        </div>

        <!-- Pagination Controls -->
        <div v-if="houses.length > 0" class="pagination-container text-center mt-4 pb-4">

          <button
            class="btn custom-btn ms-2 text-white"
            :disabled="page >= totalPages - 1"
            @click="nextPage"
          >التالي</button>
          <span class="text-white">الصفحة {{ page + 1 }} من {{ totalPages }}</span>
          <button
            class="btn custom-btn me-2 text-white"
            :disabled="page === 0"
            @click="prevPage"
          >السابق</button>
        </div>
      </div>
    </UserLayout>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import SearchBar from "@/components/SearchBar.vue";
import HouseCard from "@/components/HouseCard.vue";
import UserLayout from "@/layouts/UserLayout.vue";
import apiClient from "@/services/apiClient";

const houses = ref([]);
const router = useRouter();
const page = ref(0);
const size = ref(6);
const totalPages = ref(1);
const hasSearched = ref(false);
const loading = ref(false); // <-- New loading state

const constSearchParams =  ref({});
const fetchHouses = async (searchParams = {}) => {
  try {
    loading.value = true; // Start loading
    hasSearched.value = true;

    const queryParams = new URLSearchParams({
      page: page.value,
      size: size.value,
      sortBy: "price",
      direction: "asc"
    }).toString();

    if(Object.keys(searchParams).length>0) {
      constSearchParams.value = searchParams
    }
    console.log(constSearchParams.value,'const')
    const response = await apiClient.post(`/house/search?${queryParams}`, constSearchParams.value);

    houses.value = response.data.content.map(house => ({
      houseId: house.houseId || null,
      title: house.address ?? "عنوان غير متوفر",
      city: house.city?.name ?? "مدينة غير معروفة",
      district: house.city?.district?.name ?? "حي غير معروف",
      listingType: house.listingType ?? "N/A",
      description: house.description ?? "لا يوجد وصف",
      images: Array.isArray(house.images) ? house.images.map(img => img.imageUrl) : [],
      propertyType: house.houseType?.type ?? "نوع غير معروف",
      roomsNumber: house.roomsNumber,
      bathroomsNumber: house.bathroomsNumber,
      area: house.area ?? "غير متوفر",
      price: house.price ?? "غير متوفر",
      companyName: house.companyName ?? "غير متوفر",
      companyPhoneNumber: house.companyPhoneNumber ?? "غير متوفر",
      mapsLink: house.mapsLink ?? null,
      status: house.status ?? "غير معروف",
      floor:house.floor,
      furnished: house.furnished,
      otherLinks:house.otherLinks,
      bookinsg: Array.isArray(house.bookinsg) ? house.bookinsg : []
    }));

    totalPages.value = response.data.totalPages;
  } catch (error) {
    console.error("Error fetching houses:", error);
  } finally {
    loading.value = false; // End loading
  }
};



const onSearch = (params) => {
  page.value = 0;
  fetchHouses(params);
}
const viewHouse = (id) => {
  router.push({ name: "HouseDetails", params: { id } });
};

const nextPage = () => {
  if (page.value < totalPages.value - 1) {
    page.value++;
    fetchHouses();
  }
};

const prevPage = () => {
  if (page.value > 0) {
    page.value--;
    fetchHouses();
  }
};
</script>

<style scoped>
.homepage {
  background-image: url('@/assets/back-test.png');
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow-y: scroll;
  min-height: 100vh;
}

.search-section {
  padding: 50px 0;
  border-radius: 10px;
  text-align: center;
}

.container {
  padding-top: 20px;
}

.house-cards {
  min-height: calc(100vh - 685px);
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-container button {
  min-width: 120px;
  font-size: 16px;
}

.custom-btn {
  background-color: #B38F4B !important;
  border-color: #B38F4B !important;
}

.custom-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.no-results {
  padding: 40px 0;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 12px;
  margin-top: -50px;
}
</style>
