<template>
  <div>
    <h2 class="page-title">إنشاء مستخدم إداري</h2>
    <form @submit.prevent="createUser" class="form-container">
      <div class="mb-3">
        <label for="name" class="form-label">الاسم</label>
        <input v-model="adminUser.name" type="text" class="form-control" id="name" required />
      </div>

      <div class="mb-3">
        <label for="email" class="form-label">البريد الإلكتروني</label>
        <input v-model="adminUser.email" type="email" class="form-control" id="email" required />
      </div>

      <div class="mb-3">
        <label for="password" class="form-label">كلمة المرور</label>
        <input v-model="adminUser.password" type="password" class="form-control" id="password" required />
      </div>

      <div class="mb-3">
        <label for="role" class="form-label">الدور</label>
        <select v-model="adminUser.role" class="form-control" id="role" required>
          <option value="manager">مدير عام</option>
          <option value="technical">مسؤول تقني</option>
          <option value="supervisor">مشرف إعلانات</option>
        </select>
      </div>

      <button type="submit" class="btn btn-submit">إنشاء</button>
    </form>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';

const adminUser = ref({
  name: '',
  email: '',
  password: '',
  role: 'manager',
});

const createUser = async () => {
  try {
    await axios.post('/api/admin/users', adminUser.value);
    alert(`تم إنشاء المستخدم الإداري: ${adminUser.value.name}`);
  } catch (error) {
    alert("خطأ أثناء إنشاء المستخدم الإداري");
  }
};
</script>


<style scoped>
/* Global Styles */
.page-title {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

/* Form Container */
.form-container {
  max-width: 500px;
  margin: auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Form Elements */
.form-label {
  font-size: 16px;
  color: #333;
}

.form-control {
  font-size: 14px;
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  width: 100%;
  transition: border-color 0.3s ease;
}

/* Input focus styles */
.form-control:focus {
  border-color: #007bff;
  outline: none;
}

/* Submit Button */
.btn-submit {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.btn-submit:hover {
  background-color: #0056b3;
}

.mb-3 {
  margin-bottom: 15px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-container {
    padding: 15px;
    max-width: 90%;
  }

  .page-title {
    font-size: 20px;
  }
}
</style>
