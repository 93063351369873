<template>
  <div class="search-container">
    <div class="search-box shadow-lg">
      <div class="search-tabs">
        <button :class="['tab-btn', { active: searchParams.listingType === 'BUY' }]" @click="searchParams.listingType='BUY'">للبيع</button>
        <button :class="['tab-btn', { active: searchParams.listingType === 'RENT' }]" @click="searchParams.listingType='RENT'">للإيجار</button>
        <button :class="['tab-btn', { active: !searchParams.listingType }]" @click="searchParams.listingType=''">الكل</button>
      </div>

      <div class="filters">
        <select v-model="searchParams.houseTypeId">
          <option value="0">نوع العقار</option>
          <option v-for="houseType in houseTypes" :key="houseType.id" :value="houseType.id">{{ houseType.type }}</option>
        </select>

        <input v-model="searchParams.roomsNumber" type="text" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="searchParams.roomsNumber = searchParams.roomsNumber.replace(/[^\d\u0660-\u0669]/g, '')" placeholder="عدد الغرف" />

        <input v-model="searchParams.maxPrice" type="text" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="searchParams.maxPrice = searchParams.maxPrice.replace(/[^\d\u0660-\u0669]/g, '')" placeholder="السعر إلى" />

        <input v-model="searchParams.minPrice" type="text" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="searchParams.minPrice = searchParams.minPrice.replace(/[^\d\u0660-\u0669]/g, '')" placeholder=" السعر من" />
        <select v-model="searchParams.cityId">
          <option value="0">اختر المنطقة</option>
          <option v-for="city in cities" :key="city.id" :value="city.id">{{ city.name }}</option>
        </select>

        <select v-model="searchParams.districtId" @change="fetchCities">
          <option value="0">اختر المحافظة</option>
          <option v-for="district in districts" :key="district.id" :value="district.id">{{ district.name }}</option>
        </select>

        <select v-model="searchParams.floor">
          <option value="-1">الطابق</option>
          <option value="0">طابق أرضي</option>
          <option v-for="n in 13" :key="n" :value="n">{{ n }}</option>
        </select>

        <select v-model="searchParams.furnished">
          <option value="null">فرش الشقة</option>
          <option value="true">مفروشة</option>
          <option value="false">غير مفروشة</option>
        </select>


        <input v-model="searchParams.bathroomNumber"  type="text" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="searchParams.bathroomNumber = searchParams.bathroomNumber.replace(/[^\d\u0660-\u0669]/g, '')" placeholder="عدد الحمامات" />
        <input v-model="searchParams.maxArea" type="text" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="searchParams.maxArea = searchParams.maxArea.replace(/[^\d\u0660-\u0669]/g, '')" placeholder="مساحة إلى" />
        <input v-model="searchParams.minArea" inputmode="numeric" pattern="[0-9\u0660-\u0669]*"  class="form-control"   @input="searchParams.minArea = searchParams.minArea.replace(/[^\d\u0660-\u0669]/g, '')" placeholder="مساحة من" />

        <button class="search-btn" @click="search">🔍 بحث</button>
      </div>

    </div>
  </div>

</template>

<script setup>
import { ref, onMounted, defineEmits } from "vue";
import apiClient from "@/services/apiClient";

const emit = defineEmits(["search"]);

const searchParams = ref({
  cityId: 0,
  districtId: 0,
  houseTypeId: 0,
  minPrice: '',
  maxPrice: '',
  roomsNumber: '',
  bathroomNumber: '',
  minArea: '',
  maxArea: '',
  listingType: "BUY",
  floor: '-1', // ✅ Add this
  furnished: 'null', // ✅ Add this line
});

// const resetSearchParams = () => {
//   searchParams.value = {
//     cityId: 0,
//     districtId: 0,
//     houseTypeId: 0,
//     minPrice: '',
//     maxPrice: '',
//     roomsNumber: '',
//     bathroomNumber: '',
//     minArea: '',
//     maxArea: '',
//     listingType: searchParams.value.listingType, // نحافظ على نوع البحث الحالي
//   };
// };



const districts = ref([]);
const cities = ref([]);
const houseTypes = ref([]);

const fetchHouseTypes = async () => {
  try {
    const response = await apiClient.get("/houseTypes");
    houseTypes.value = response.data;
  } catch (error) {
    console.error("Error fetching house types:", error.response?.data || error.message);
  }
};

// Fetch districts
const fetchDistricts = async () => {
  try {
    const response = await apiClient.get("/districts");
    districts.value = response.data;
  } catch (error) {
    console.error("Error fetching districts:", error.response?.data || error.message);
  }
};




// Fetch cities based on selected district
const fetchCities = async () => {
  if (!searchParams.value.districtId) return;
  try {
    const response = await apiClient.get(`/districts/${searchParams.value.districtId}/cities`);
    cities.value = response.data;
  } catch (error) {
    console.error("Error fetching cities:", error.response?.data || error.message);
  }
};

// Fetch all data on component mount
onMounted(() => {
  fetchHouseTypes();
  fetchDistricts();
});

const search = () => {
  const filteredParams = Object.fromEntries(
      Object.entries(searchParams.value).filter(([, value]) => value !== 0 && value !== "")
  );

  emit("search", filteredParams);
};



</script>

<style scoped>
.search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 120px;
}

.search-box {
  background: rgba(255,255,255,0.4);
  backdrop-filter: blur(12px);
  padding: 20px;
  border-radius: 18px;
  width: 100%;
  max-width: 1100px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.3);
}

.search-tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.tab-btn {
  flex: 1;
  padding: 12px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.6);
  color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tab-btn:hover {
  background-color: rgba(255, 255, 255, 0.9);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.tab-btn:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tab-btn.active {
  background-color: #B38F4B;
  color: white;
}

.filters {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 12px;
}

.filters select,
.filters input {
  padding: 10px;
  border-radius: 12px;
  border: none;
  background-color: rgba(255,255,255,0.6);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.search-btn {
  padding: 10px 50px;
  margin-top: 10px;
  background-color: #B38F4B;
  color: white;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  transition: 0.3s;
}

.search-btn:hover {
  background-color: #B38F4B;
  transform: translateY(-2px);
}

/* Adjust placeholder colors */
::placeholder {
  color: #444;
}

/* Fix modal styling conflicts */
.modal-content {
  backdrop-filter: blur(15px);
  background-color: rgba(255,255,255,0.9);
  border-radius: 15px;
}
</style>
