<template>
  <UserLayout>
    <div class="container mt-4">
      <div class="profile-card">
        <h2>👤 حسابي</h2>
        <form @submit.prevent="updateProfile">
          <div class="mb-3">
            <label class="form-label">الاسم</label>
            <input v-model="user.name" type="text" class="form-control" required />
          </div>
          <div class="mb-3">
            <label class="form-label">البريد الإلكتروني</label>
            <input v-model="user.email" type="email" class="form-control" disabled />
          </div>
          <div class="mb-3">
            <label class="form-label">رقم الهاتف</label>
            <input v-model="user.phone" type="text" class="form-control" required />
          </div>
          <button type="submit" class="btn btn-primary w-100">💾 حفظ التعديلات</button>
        </form>
      </div>
    </div>
  </UserLayout>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import UserLayout from "@/layouts/UserLayout.vue";

const user = ref({ name: "", email: "", phone: "" });

onMounted(() => {
  const storedUser = JSON.parse(localStorage.getItem("user")) || {
    name: "محمد أحمد",
    email: "mohammed@example.com",
    phone: "0123456789",
  };
  user.value = storedUser;
});

const updateProfile = () => {
  localStorage.setItem("user", JSON.stringify(user.value));
  alert("تم حفظ التعديلات بنجاح!");
};
</script>

<style scoped>
.profile-card {
  max-width: 500px;
  margin: auto;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>
