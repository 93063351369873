<template>
<div class="company-name">
  {{ companyName }}
</div>
  <div class="admin-layout">
    <!-- Sidebar -->
    <div class="sidebar">
      <div class="sidebar-header">
        <h4>🏢 إدارة العقارات</h4>
      </div>
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link to="/admin-user/view-apartments" class="nav-link" active-class="active">عرض الشقق</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/admin-user/create-apartment" class="nav-link" active-class="active">إنشاء شقة</router-link>
        </li>
        <li v-if="user.user.userType==='ROLE_CUSTOMER_ADMIN'" class="nav-item">
          <router-link to="/admin-user/users" class="nav-link" active-class="active">قائمة المستخدمين</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/admin-user/request-apartment" class="nav-link" active-class="active">طلبات الحجز</router-link>
        </li>
        <li v-if="user.user.userType==='ROLE_CUSTOMER_ADMIN'"  class="nav-item">
          <router-link to="/admin-user/create-sub-admin" class="nav-link" active-class="active">إنشاء مستخدم فرعي</router-link>
        </li>
<!--        <li class="nav-item">-->
<!--          <router-link to="/admin-user/calendar" class="nav-link" active-class="active">التقويم</router-link>-->
<!--        </li>-->

        <li class="nav-item">
          <button class="nav-link btn btn-link text-danger" @click="logout">
            <i class="fas fa-sign-out-alt"></i> الخروج
          </button>
        </li>

      </ul>
    </div>
<!--    <div class="modal fade" id="signInModal" tabindex="-1" aria-hidden="true">-->
<!--      <div class="modal-dialog">-->
<!--        <div class="modal-content">-->
<!--          <div class="modal-header">-->
<!--            <h5 class="modal-title">{{ isLogin ? 'تسجيل الدخول' : 'إنشاء حساب' }}</h5>-->
<!--            <button type="button" class="btn-close" data-bs-dismiss="modal"></button>-->
<!--          </div>-->
<!--          <div class="modal-body">-->
<!--            <ul class="nav nav-tabs mb-3">-->
<!--              <li class="nav-item">-->
<!--                <button class="nav-link" :class="{ active: isLogin }" @click="isLogin = true">تسجيل الدخول</button>-->
<!--              </li>-->
<!--              <li class="nav-item">-->
<!--                <button class="nav-link" :class="{ active: !isLogin }" @click="isLogin = false">التسجيل</button>-->
<!--              </li>-->
<!--            </ul>-->

<!--            <form v-if="isLogin" @submit.prevent="signIn">-->
<!--              <div class="mb-3">-->
<!--                <label class="form-label">اسم الحساب</label>-->
<!--                <input v-model="username" type="text" class="form-control" required />-->
<!--              </div>-->
<!--              <div class="mb-3 position-relative">-->
<!--                <label class="form-label">كلمة المرور</label>-->
<!--                <input :type="showPassword ? 'text' : 'password'" v-model="password" class="form-control" required />-->
<!--                <span class="eye-icon position-absolute" @click="togglePassword">👁️</span>-->
<!--              </div>-->
<!--              <button type="submit" class="btn btn-primary w-100">دخول</button>-->
<!--            </form>-->

<!--            <div v-else>-->
<!--              <ul class="nav nav-pills mb-3">-->
<!--                <li class="nav-item">-->
<!--                  <button class="nav-link" :class="{ active: userType === 'ROLE_USER' }" @click="userType = 'ROLE_USER'; registerData.role = userType">مستخدم عادي</button>-->
<!--                </li>-->
<!--                <li class="nav-item">-->
<!--                  <button class="nav-link" :class="{ active: userType === 'ROLE_CUSTOMER_ADMIN' }" @click="userType = 'ROLE_CUSTOMER_ADMIN'; registerData.role = userType">مسؤول عقاري</button>-->
<!--                </li>-->
<!--              </ul>-->

<!--              <form @submit.prevent="register">-->
<!--                <div class="mb-3">-->
<!--                  <label class="form-label">الاسم</label>-->
<!--                  <input v-model="registerData.username" type="text" class="form-control" required />-->
<!--                </div>-->
<!--                <div class="mb-3">-->
<!--                  <label class="form-label">البريد الإلكتروني</label>-->
<!--                  <input v-model="registerData.email" type="email" class="form-control" required />-->
<!--                </div>-->
<!--                <div class="mb-3">-->
<!--                  <label class="form-label">كلمة المرور</label>-->
<!--                  <input v-model="registerData.password" type="password" class="form-control" required />-->
<!--                </div>-->

<!--                &lt;!&ndash; Admin-User Additional Fields &ndash;&gt;-->
<!--                <div v-if="userType === 'ROLE_CUSTOMER_ADMIN'">-->
<!--                  <div class="mb-3">-->
<!--                    <label class="form-label">اسم المكتب العقاري</label>-->
<!--                    <input v-model="registerData.companyName" type="text" class="form-control" required />-->
<!--                  </div>-->
<!--                  <div class="mb-3">-->
<!--                    <label class="form-label">عنوان الشركة</label>-->
<!--                    <input v-model="registerData.companyAddress" type="text" class="form-control" required />-->
<!--                  </div>-->
<!--                  <div class="mb-3">-->
<!--                    <label class="form-label">رقم الهاتف</label>-->
<!--                    <input v-model="registerData.companyPhoneNumber" type="text" class="form-control" required />-->
<!--                  </div>-->
<!--                  <div class="mb-3">-->
<!--                    <label class="form-label">وصف الشركة</label>-->
<!--                    <textarea v-model="registerData.companyDescription" class="form-control" required></textarea>-->
<!--                  </div>-->
<!--                  <div class="mb-3">-->
<!--                    <label class="form-label">المستندات الرسمية</label>-->
<!--                    <input type="file" class="form-control" @change="uploadDocuments" multiple />-->
<!--                  </div>-->
<!--                </div>-->

<!--                <button type="submit" class="btn btn-success w-100">تسجيل</button>-->
<!--              </form>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--    &lt;!&ndash; Main Content &ndash;&gt;-->
<!--    <div class="main-content">-->
<!--      <router-view></router-view> &lt;!&ndash; The content of each page will be injected here &ndash;&gt;-->
<!--    </div>-->
<!--  </div>-->
<!--    </div>-->
    <div class="main-content">
      <router-view></router-view> <!-- The content of each page will be injected here -->
    </div>
  </div>
</template>

<script setup>
import {useUser} from "@/composables/useUser";

const companyName = localStorage.getItem('companyName')
const user = useUser()
const logout = () => {localStorage.clear(); window.location.href = '/';}

// No script needed for layout. Just the structure for sidebar navigation.
</script>

<style scoped>
.admin-layout {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 250px;
  background-color: #3B412C;
  padding: 20px;
  color: white;
}

.sidebar-header {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}

.nav-link {
  color: #ccc;
  text-decoration: none;
  padding: 12px;
  font-size: 16px;
  border-radius: 6px;
  display: block;
  margin-bottom: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover {
  background-color: #B38F4B;
  color: white;
}

.nav-link.active {
  background-color: #B38F4B;
  color: white;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #f8f9fa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-left: 20px;
  max-height: 100vh;
  overflow-y: scroll;
}
.company-name {
  z-index: -10;
  background: #3B412C;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-weight: 600;         /* Semi-bold for a modern look */
  font-size: 1.25rem;       /* Slightly bigger (you can tweak it) */
  letter-spacing: 0.5px;    /* Slight spacing for elegance */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Clean professional font */
}

@media (max-width: 768px) {
  .admin-layout {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  .main-content {
    margin-left: 0;
  }
}
</style>
