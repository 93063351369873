<template>
  <div class="admin-layout">
    <!-- Sidebar -->
    <div class="sidebar">
      <div class="sidebar-header">
        <h4>🔧 لوحة تحكم المسؤول</h4>
      </div>
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link to="/admin/list-admin-users" class="nav-link" active-class="active">قائمة المستخدمين الإداريين</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/admin/admin-users" class="nav-link" active-class="active">قائمة المستخدمين</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/admin/companies" class="nav-link" active-class="active">قائمة الوكلاء العقاريين</router-link>
        </li>

        <!--        <li class="nav-item">-->
<!--          <router-link to="/admin/create-admin-user" class="nav-link" active-class="active">إنشاء مستخدم إداري</router-link>-->
<!--        </li>-->
        <li class="nav-item">
          <router-link to="/admin/pending-apartments" class="nav-link" active-class="active">الشركات المعلقة للموافقة</router-link>
        </li>
        <li class="nav-item">
          <button class="nav-link btn btn-link text-danger" @click="logout">
            <i class="fas fa-sign-out-alt"></i> الخروج
          </button>
        </li>
      </ul>
    </div>

    <!-- Main Content -->
    <div class="main-content">
      <router-view></router-view> <!-- The content of each page will be injected here -->
    </div>
  </div>
</template>

<script setup>
const logout = () => {localStorage.clear(); window.location.href = '/';}
// No additional logic needed for layout
</script>

<style scoped>
.admin-layout {
  display: flex;
  height: 100vh;
}


.sidebar {
  width: 250px;
  background-color: #3B412C;
  padding: 20px;
  color: white;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar-header {
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}

.nav-link {
  color: #ccc;
  text-decoration: none;
  padding: 12px;
  font-size: 16px;
  border-radius: 6px;
  display: block;
  margin-bottom: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover {
  background-color: #B38F4B;
  color: white;
}

.nav-link.active {
  background-color: #B38F4B;
  color: white;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  background-color: #f8f9fa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  margin-left: 20px;
  max-height: 100vh;
  overflow-y: scroll;
}

@media (max-width: 768px) {
  .admin-layout {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  .main-content {
    margin-left: 0;
  }
}
</style>
